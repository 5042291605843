import { Stack } from '@mui/material'

import { fbLink, linkedInLink } from '@shared/constants'

import { FacebookIcon } from '../assets/facebook.icon'
import { LinkedinIcon } from '../assets/linkedin.icon'

export function SocialLinks() {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        mt: { xs: 4.5, lg: 11 },
      }}
    >
      <a
        style={{
          textDecoration: 'none',
          color: 'white',
        }}
        href={linkedInLink}
      >
        <LinkedinIcon sx={{ fontSize: { xs: 24, lg: 32 } }} />
      </a>

      <a
        style={{
          textDecoration: 'none',
          color: 'white',
        }}
        href={fbLink}
      >
        <FacebookIcon sx={{ fontSize: { xs: 24, lg: 32 } }} />
      </a>
    </Stack>
  )
}
