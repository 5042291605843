import { useMediaQuery, Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { StyledTextTypography, Styled404Typography } from '../styled'

export function PageNotFound() {
  const { t } = useTranslation('fsd')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {mdAndUp ? (
        <StyledTextTypography>
          {`${t('landing.pages.error.Error')} 404 : ${t(
            'landing.pages.error.Page Not Found'
          )}`}
        </StyledTextTypography>
      ) : (
        <Box ml={-4}>
          <Styled404Typography>404</Styled404Typography>

          <StyledTextTypography>
            {t('landing.pages.error.Page Not Found')}
          </StyledTextTypography>
        </Box>
      )}
    </>
  )
}
