import { styled } from '@mui/material'
// TODO Add to typography theme
export const MainPageSectionTitle = styled('h2')(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  fontWeight: 500,
  fontSize: 28,
  lineHeight: '120%',
  margin: 0,
  [theme.breakpoints.up('lg')]: {
    fontSize: 40,
  },
}))
