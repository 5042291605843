import { Box, styled } from '@mui/material'

export const StyledSocialBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.25),
  backgroundColor: '#009EE0',
  borderRadius: theme.spacing(1),
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#EAEAEA',
    '&:hover': {
      backgroundColor: '#009EE0',
    },
  },
  '& svg': {
    display: 'block',
  },
}))
