import { Box, styled } from '@mui/material'

export const StyledBoxBackgroundDesktop = styled(Box)(({ theme }) => ({
  background: '#F6F5EE',
  borderRadius: '12px 0px 0px 12px',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: '16%',
  [theme.breakpoints.up(1920)]: {
    left: '29%',
  },
  [theme.breakpoints.up(3000)]: {
    left: '35%',
  },
}))
