import { useEffect } from 'react'
import { configs } from '@libs/app-settings'
import { useAuth } from '@libs/auth'

export function RedirectToAuthPage() {
  const { startLoginProcess } = useAuth()

  const { getServiceFromURLSearchParams } = configs()

  const serviceFromUrlParam = getServiceFromURLSearchParams()

  useEffect(() => {
    startLoginProcess(serviceFromUrlParam)
  }, [])

  return <></>
}
