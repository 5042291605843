import { AxiosError } from 'axios'

import { getCookie } from '../../../utils'
import { __TOKEN } from '../constants'
import { IUser } from '../types'
import { instanceApi } from './api-instance'
import { logoutRequest } from './logout-request'

export async function getUserRequest(): Promise<null | IUser> {
  try {
    const token = getCookie(__TOKEN)

    if (!token) {
      throw new Error('Unauthorized')
    }

    const url = `/user`

    const response = await instanceApi.get<IUser>(url, {
      headers: {
        Authorization: token,
      },
    })

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      (error as AxiosError)?.response?.status === 404 ||
      (error as AxiosError)?.response?.status === 403
    ) {
      await logoutRequest()
    }

    return null
  }
}
