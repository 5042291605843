import { SvgIcon } from '@mui/material'

export default function CalendarIcon() {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25C8.41421 1.25 8.75 1.58579 8.75 2V2.75H15.25V2C15.25 1.58579 15.5858 1.25 16 1.25C16.4142 1.25 16.75 1.58579 16.75 2V2.78025C18.2859 2.90808 19.5038 3.44034 20.3621 4.36466C21.3464 5.42465 21.75 6.88503 21.75 8.5V17C21.75 18.615 21.3464 20.0754 20.3621 21.1353C19.3685 22.2054 17.893 22.75 16 22.75H8C6.10705 22.75 4.63152 22.2054 3.6379 21.1353C2.65363 20.0754 2.25 18.615 2.25 17V8.5C2.25 6.88503 2.65363 5.42465 3.6379 4.36466C4.4962 3.44034 5.71409 2.90808 7.25 2.78025V2C7.25 1.58579 7.58579 1.25 8 1.25ZM7.25 4.28628C6.06502 4.40668 5.26412 4.81778 4.7371 5.38534C4.12128 6.04852 3.77738 7.03465 3.75157 8.34H20.2484C20.2226 7.03465 19.8787 6.04852 19.2629 5.38534C18.7359 4.81778 17.935 4.40668 16.75 4.28628V5C16.75 5.41421 16.4142 5.75 16 5.75C15.5858 5.75 15.25 5.41421 15.25 5V4.25H8.75V5C8.75 5.41421 8.41421 5.75 8 5.75C7.58579 5.75 7.25 5.41421 7.25 5V4.28628ZM20.25 9.84H3.75V17C3.75 18.385 4.09637 19.4246 4.7371 20.1147C5.36848 20.7946 6.39295 21.25 8 21.25H16C17.607 21.25 18.6315 20.7946 19.2629 20.1147C19.9036 19.4246 20.25 18.385 20.25 17V9.84Z"
        fill="#0155B7"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29297 13.7C7.29297 13.1477 7.74068 12.7 8.29297 12.7H8.30297C8.85525 12.7 9.30297 13.1477 9.30297 13.7C9.30297 14.2523 8.85525 14.7 8.30297 14.7H8.29297C7.74068 14.7 7.29297 14.2523 7.29297 13.7ZM10.994 13.7C10.994 13.1477 11.4417 12.7 11.994 12.7H12.004C12.5563 12.7 13.004 13.1477 13.004 13.7C13.004 14.2523 12.5563 14.7 12.004 14.7H11.994C11.4417 14.7 10.994 14.2523 10.994 13.7ZM14.694 13.7C14.694 13.1477 15.1417 12.7 15.694 12.7H15.703C16.2553 12.7 16.703 13.1477 16.703 13.7C16.703 14.2523 16.2553 14.7 15.703 14.7H15.694C15.1417 14.7 14.694 14.2523 14.694 13.7ZM7.29297 16.7C7.29297 16.1477 7.74068 15.7 8.29297 15.7H8.30297C8.85525 15.7 9.30297 16.1477 9.30297 16.7C9.30297 17.2523 8.85525 17.7 8.30297 17.7H8.29297C7.74068 17.7 7.29297 17.2523 7.29297 16.7ZM10.994 16.7C10.994 16.1477 11.4417 15.7 11.994 15.7H12.004C12.5563 15.7 13.004 16.1477 13.004 16.7C13.004 17.2523 12.5563 17.7 12.004 17.7H11.994C11.4417 17.7 10.994 17.2523 10.994 16.7ZM14.694 16.7C14.694 16.1477 15.1417 15.7 15.694 15.7H15.703C16.2553 15.7 16.703 16.1477 16.703 16.7C16.703 17.2523 16.2553 17.7 15.703 17.7H15.694C15.1417 17.7 14.694 17.2523 14.694 16.7Z"
        fill="#0155B7"
      />
    </SvgIcon>
  )
}
