import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IGlobalLoaderState {
  show: boolean
  setShow: (payload: boolean) => void
}

export const useMobileMenu = create<IGlobalLoaderState>()(
  devtools(
    (set) => ({
      show: false,
      setShow: (show) => {
        set(() => ({ show }))
      },
    }),
    { name: 'mobile-menu-store' }
  )
)
