import { SvgIcon, SxProps } from '@mui/material'

export function MainLogoIcon(props: { sx?: SxProps }) {
  return (
    <SvgIcon {...props} viewBox="0 0 308 70">
      <path
        d="M29.4212 29.8748C27.7389 29.8748 26.3749 31.2389 26.3749 32.9208C26.3749 34.6035 27.7389 35.9672 29.4212 35.9672C31.1036 35.9672 32.4672 34.6035 32.4672 32.9208C32.4672 31.2389 31.1036 29.8748 29.4212 29.8748ZM32.4922 55.7825L33.7121 48.2378C33.7466 48.1222 33.7708 48.0027 33.7708 47.8762C33.7708 47.1729 33.2006 46.6031 32.4977 46.6031C31.7947 46.6031 31.2246 47.1729 31.2246 47.8762C31.2246 48.0012 31.2484 48.12 31.2821 48.2345L32.4922 55.7825ZM29.4212 41.5554C28.7179 41.5554 28.1481 42.1256 28.1481 42.8285L29.4267 69.0593L30.694 42.8285C30.694 42.1256 30.1242 41.5554 29.4212 41.5554ZM27.56 48.2345C27.5937 48.12 27.6175 48.0012 27.6175 47.8762C27.6175 47.1729 27.0474 46.6031 26.3444 46.6031C25.6415 46.6031 25.0713 47.1729 25.0713 47.8762C25.0713 48.0027 25.0955 48.1222 25.13 48.2378L26.3499 55.7825L27.56 48.2345ZM58.8421 58.3951H58.834C53.7159 54.8116 47.4901 52.7021 40.7678 52.7021V44.432L34.909 38.5948C35.9023 35.6647 36.4453 32.5274 36.4453 29.2614C36.4453 22.0195 33.7957 15.4002 29.4212 10.3055C25.0464 15.4002 22.3968 22.0195 22.3968 29.2614C22.3968 32.5274 22.9398 35.6647 23.9331 38.5948L18.0744 44.432V52.7021C11.352 52.7021 5.12618 54.8116 0.0080681 58.3951H0C9.60186 40.6409 16.1574 20.9979 18.9712 0.167572H39.8709C42.6847 20.9979 49.2399 40.6409 58.8421 58.3951Z"
        fill="#009EE0"
      />

      <path
        d="M96.6186 35.1459C94.0665 30.6979 90.2748 28.1459 85.6811 27.125C94.7227 26.3959 100.994 22.0208 100.994 13.6354C100.994 5.46875 94.0665 0 83.7852 0H59.7957V0.291667C60.0144 1.16667 61.7644 6.5625 61.7644 11.5208V40.9792C61.7644 45.9375 60.0144 51.3334 59.7957 52.2084V52.5H74.9623V52.2084C74.8165 51.3334 73.0665 45.9375 73.0665 40.9792V28.2188H76.0561C79.2644 28.9479 83.2748 32.1563 86.1915 39.0834C88.379 44.1875 91.2227 50.6771 93.2644 52.5H106.827V52.2084C102.233 47.9063 100.702 42.5834 96.6186 35.1459ZM73.0665 2.77083H79.9207C85.8269 2.77083 88.8894 7.29167 88.8894 14.2188C88.8894 21.4375 85.6082 25.2292 79.7019 25.2292H73.0665V2.77083ZM74.5977 28.0729C74.379 28.1459 74.0873 28.1458 73.8686 28.1458C74.0873 28.0729 74.3061 28.0729 74.5977 28.0729Z"
        fill="#009EE0"
      />

      <path
        d="M139.539 25.5938C145.08 23.7708 150.184 20.1979 150.184 13.5625C150.184 5.97917 143.33 0 133.486 0H109.57V0.291667C109.789 1.16667 111.539 6.5625 111.539 11.5208V40.9792C111.539 45.9375 109.789 51.3334 109.57 52.2084V52.5H134.289C144.059 52.5 152.591 47.9792 152.591 38.7917C152.591 30.9167 146.101 27.1979 139.539 25.5938ZM129.622 3.13542C135.528 3.13542 138.299 8.67709 138.299 13.5625C138.299 18.8125 135.309 24.3542 129.403 24.3542H122.841V3.13542H129.622ZM130.424 49.3646H122.841V27.4896H130.424C137.351 27.4896 140.851 32.375 140.851 38.7188C140.851 45.4271 137.57 49.3646 130.424 49.3646Z"
        fill="#009EE0"
      />

      <path
        d="M157.221 52.5H172.315V52.2084C172.169 51.3334 170.419 45.9375 170.419 40.9792V11.5208C170.419 6.5625 172.169 1.16667 172.315 0.291667V0H157.221V0.291667C157.367 1.16667 159.117 6.5625 159.117 11.5208V40.9792C159.117 45.9375 157.367 51.3334 157.221 52.2084V52.5Z"
        fill="#009EE0"
      />

      <path
        d="M202.049 0H178.351V0.291667C178.497 1.16667 180.247 6.5625 180.247 11.5208V40.9792C180.247 45.9375 178.497 51.3334 178.351 52.2084V52.5H193.518V52.2084C193.299 51.3334 191.622 45.9375 191.622 40.9792V31.9375H199.497C209.414 31.9375 219.33 26.6146 219.33 15.8958C219.33 7 212.33 0 202.049 0ZM197.966 28.5834H191.622V3.35417H198.185C205.185 3.35417 207.445 10.5729 207.445 15.8958C207.445 21.5833 204.966 28.5834 197.966 28.5834Z"
        fill="#009EE0"
      />

      <path
        d="M259.092 40.5417L243.852 0H230.581V0.218751C230.873 0.947918 232.769 5.10417 231.456 8.89584L221.248 37.1875C218.915 43.75 216.29 49.5834 214.758 52.2813V52.5H225.04V52.1354C224.675 50.9688 222.342 44.3334 224.529 38.2813L225.769 34.7084H244.727L246.915 40.4688C248.81 45.5729 247.571 51.1875 247.279 52.2084V52.5H264.779V52.2813C262.81 49.3646 260.988 45.6459 259.092 40.5417ZM226.935 31.4271L235.029 8.75L243.56 31.4271H226.935Z"
        fill="#009EE0"
      />

      <path
        d="M305.011 0H294.584V0.364583C294.948 1.45833 296.334 7.51042 293.636 12.9063L285.469 29.2396L276.865 11.5938C274.459 6.70834 275.334 1.3125 275.698 0.291667V0H258.271V0.291667C258.855 1.23959 262.209 5.97917 264.396 10.4271L276.136 32.5938V40.9792C276.136 45.9375 274.386 51.3334 274.24 52.2084V52.5H289.407V52.2084C289.261 51.3334 287.511 45.9375 287.511 40.9792V32.375L298.886 10.5C301.001 6.05209 304.573 1.16667 305.011 0.291667V0Z"
        fill="#009EE0"
      />
    </SvgIcon>
  )
}
