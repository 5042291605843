import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'
import * as zod from 'zod'

import { configs } from '@libs/app-settings'

import { AppBar } from '@widgets/app-bar'
import { RegistrationToolbar, ProgressSteps } from '@widgets/registration'

import {
  useSessionStore,
  IFastRegistrationFormState,
  TRegistrationOutletContext,
} from '@entities/registration'
import { useValidationPhoneNumberStore } from '@shared/ui'

import { StyledContainer } from './styled'

export function RegistrationLayout() {
  const { pathname } = useLocation()
  const { getServiceFromURLSearchParams } = configs()
  const serviceFromUrlParam = getServiceFromURLSearchParams()
  const [service, setService] = useState<string | undefined>(undefined)

  const { setSessionId, setNextTry } = useSessionStore((state) => state)

  const { stringLength } = useValidationPhoneNumberStore((state) => state)

  const schema = zod.object({
    phoneNumber: zod.string().min(stringLength),
    emailAddress: zod.string().min(6).max(100).email(),
    phoneNumberVerificationCode: zod.string(),
    emailAddressVerificationCode: zod.string(),
  })

  const form = useForm<IFastRegistrationFormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      phoneNumber: '',
      emailAddress: '',
      phoneNumberVerificationCode: '',
      emailAddressVerificationCode: '',
    },
    mode: 'onSubmit',
  })

  const { reset } = form

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [pathname])

  useEffect(() => {
    reset()

    setSessionId('')

    setNextTry(false)
  }, [])

  useEffect(() => {
    if (serviceFromUrlParam) {
      setService(serviceFromUrlParam)
    }
  }, [serviceFromUrlParam])

  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle="ArbiId"
        titleTemplate="%s - ArbiId"
        link={[{ rel: 'icon', href: '/arbi-id-favicon.svg' }]}
      />

      <ScrollRestoration />
      <AppBar registration />

      <StyledContainer maxWidth="lg" component="main">
        <RegistrationToolbar sx={{ mb: 4 }} />
        <ProgressSteps />

        <FormProvider {...form}>
          <Outlet context={{ service } satisfies TRegistrationOutletContext} />
        </FormProvider>
      </StyledContainer>
    </>
  )
}
