import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const DocumentsInfoPageRoutes: RouteObject[] = [
  {
    path: routePaths.documentsInfoBusiness,
    lazy: async () => {
      const { DocumentsInfo } = await import('./ui')

      return { Component: () => DocumentsInfo({ type: 'business' }) }
    },
  },
  {
    path: routePaths.documentsInfoPersonal,
    lazy: async () => {
      const { DocumentsInfo } = await import('./ui')

      return { Component: () => DocumentsInfo({ type: 'personal' }) }
    },
  },
]
