import axios from 'axios'

import {
  CRYPTO_URL,
  KEYCLOAK_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  BANKING_URL,
} from '@shared/constants'

import { configs } from '../../../app-settings'
import { getCookie, formEncode, setCookie, secondsToDays } from '../../../utils'
import { logoutRequest } from '../api'
import { __TOKEN, __REFRESH_TOKEN } from '../constants'

const currentService = configs().getHostService()

const BaseURL = currentService === 'crypto' ? CRYPTO_URL : BANKING_URL

const instanceApi = axios.create({
  baseURL: BaseURL,
})

const keycloakApi = axios.create({
  baseURL: `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
})

instanceApi.interceptors.request.use(
  async (config) => {
    // Do something before request is sent

    config.headers && (config.headers.Authorization = getCookie(__TOKEN))

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

instanceApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      !error ||
      !error.response ||
      (error.response &&
        error.response.status > 401 &&
        error.response.status !== 403)
    ) {
      return Promise.reject(error || new Error('Bad request'))
    }

    const { response, config } = error
    const { status } = response

    try {
      if (status === 403) {
        logoutRequest()

        return Promise.reject(error)
      }

      if (status === 401) {
        const refreshToken = getCookie(__REFRESH_TOKEN)

        if (!refreshToken) {
          return Promise.reject(error)
        }

        const refreshTokenWithoutBearer = refreshToken.split(' ')[1]

        const body = formEncode({
          grant_type: 'refresh_token',
          client_id: KEYCLOAK_CLIENT_ID,
          refresh_token: refreshTokenWithoutBearer,
        })

        const resp = await keycloakApi.post('', body, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })

        setCookie(
          __TOKEN,
          `Bearer ${resp.data.access_token}`,
          secondsToDays(resp.data.expires_in)
        )

        setCookie(
          __REFRESH_TOKEN,
          `Bearer ${resp.data.refresh_token}`,
          secondsToDays(resp.data.refresh_expires_in)
        )

        const secondRequestResponse = await axios.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: 'Bearer ' + resp.data.access_token,
          },
        })

        if (!secondRequestResponse) {
          return Promise.reject(error)
        }

        return secondRequestResponse
      } else {
        return Promise.reject(error)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
)

export { instanceApi }
