import { styled, Typography } from '@mui/material'

export const TextFieldStyledLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '20px',
  fontFeatureSettings: "'zero' on",
  color: '#525252',
  marginBottom: 2,
}))
