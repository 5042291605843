import {
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { QontoStepIcon } from './components'

export function ProgressSteps() {
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))
  const [progressStep, setProgressStep] = useState<number>(1)
  const { pathname } = useLocation()

  useEffect(() => {
    switch (pathname) {
      case routePaths.emailVerification:
        setProgressStep(2)

        break
      case routePaths.registrationFinish:
        setProgressStep(3)

        break
      case routePaths.phoneNumberVerification:
      default:
        setProgressStep(1)

        break
    }
  }, [pathname])

  return (
    <Stepper
      activeStep={progressStep}
      alternativeLabel={lgAndUp}
      sx={{ maxWidth: 904, mx: 'auto' }}
    >
      {[...Array(3)].map((_, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={QontoStepIcon} />
        </Step>
      ))}
    </Stepper>
  )
}
