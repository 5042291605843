import { styled } from '@mui/material'
import { StandardButton } from '@shared/ui'

export const StyledButton = styled(StandardButton)(({ theme }) => ({
  minWidth: 120,
  height: 40,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: 500,
  [theme.breakpoints.up('md')]: {
    height: 'initial',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
