import { styled } from '@mui/material'
//TODO add this to the theme
export const AccountSecondaryTitle = styled('h5')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '22px',
  letterSpacing: '0.02em',
  marginBottom: 20,
  margin: 0,
  [theme.breakpoints.up('lg')]: {
    fontSize: 20,
    lineHeight: '24px',
  },
}))
