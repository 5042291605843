/* eslint-disable react/jsx-newline */
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SxProps,
  TextField,
  useTheme,
  styled,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import _ from 'lodash'
import React, {
  ChangeEvent,
  ForwardedRef,
  useEffect,
  useState,
  KeyboardEvent,
} from 'react'
import { FieldPath, UseFormSetValue } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import { countriesListWithEmoji, ICountryWithEmoji } from '@libs/constants'
// import { SearchField } from '@libs/ui/custom-form-fields/search-field'
// import { TextFieldStyledLabel } from '@libs/ui/custom-form-fields/styled'
import { convertPhoneNumberToApi } from '@libs/utils'
import { getUserIP } from '@libs/utils'
import { SearchField, TextFieldStyledLabel } from '@shared/ui'
import { useValidationPhoneNumberStore } from '../model'

interface IProps {
  label?: string
  sx?: SxProps
  fullWidth?: boolean
  value?: string
  placeholder?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onCountryChange?: (code: string) => void
  error?: boolean
  helperText?: string | boolean
  // TODO: FIX any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: UseFormSetValue<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nameField?: FieldPath<any>
  disabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  required?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}

const StyledEmojiSpan = styled('span')(() => ({
  fontSize: 24,
  fontFamily: 'Noto Color Emoji, sans-serif',
}))

function UiPhoneNumberFieldWithRef(
  {
    sx,
    fullWidth,
    value,
    placeholder,
    onChange,
    onCountryChange,
    error,
    helperText,
    label,
    nameField,
    setValue,
    disabled,
    onFocus,
    onBlur,
    required,
    onKeyDown,
  }: IProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [country, setCountry] = useState<ICountryWithEmoji | undefined>()

  const [visibleCountryList, setVisibleCountryList] = useState<
    ICountryWithEmoji[]
  >(countriesListWithEmoji)

  const [lazyList, setLazyList] = useState(countriesListWithEmoji.slice(0, 7))
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLElement>(null)
  const { setStringLength } = useValidationPhoneNumberStore((state) => state)

  const phoneNumber = window.document.querySelector('.phone-number')
  const inputPhoneNumber = phoneNumber?.querySelector('input')

  function setCountryHandler(country: ICountryWithEmoji | undefined) {
    if (onCountryChange && country) {
      onCountryChange(country.code)
    }

    setCountry(country)
  }

  useEffect(() => {
    const country = countriesListWithEmoji.find((elem) => elem.iso === 'SK')

    setCountryHandler(country)
  }, [])

  const getCountryMask = () => {
    return typeof country?.mask === 'string' ? country?.mask : country?.mask[0]
  }

  const getCurrentCountry = async () => {
    const geolocationInfo = await getUserIP()

    if (!geolocationInfo) {
      return
    }

    const currentCountry = countriesListWithEmoji.find(
      (elem) => elem.iso === geolocationInfo.country_code
    )

    if (currentCountry) {
      setCountryHandler(currentCountry)
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => {
      window.document.body.style.overflow = !prevOpen ? 'hidden' : 'auto'

      return !prevOpen
    })
  }

  useEffect(() => {
    getCurrentCountry()

    return () => {
      window.document.body.style.overflow = 'auto'
    }
  }, [])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()

      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  function handleChooseCountry(country: (typeof countriesListWithEmoji)[0]) {
    handleSetCountry(country)

    setOpen(false)

    window.document.body.style.overflow = 'auto'
  }

  function handleSetCountry(country: (typeof countriesListWithEmoji)[0]) {
    setCountry(country)

    if (onCountryChange) {
      inputPhoneNumber?.focus()

      onCountryChange(country?.code)
    }

    if (setValue && nameField) {
      setValue(nameField, country?.code)
    }

    window.document.body.style.overflow = 'auto'
  }

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    const mask = getCountryMask()

    setStringLength((mask || '').length)
  }, [country])

  const changerHelperText = (helperText: string | boolean | undefined) => {
    if (
      !(typeof helperText === 'string') ||
      helperText.indexOf('String must contain at least') !== 0
    ) {
      return helperText
    }

    if (typeof getCountryMask() === 'string') {
      const mask = getCountryMask()

      const amountOfNumbers = convertPhoneNumberToApi(mask || '').length

      return `String must contain at least ${amountOfNumbers} digits`
    }

    return helperText
  }

  useEffect(() => {
    if (!open) {
      setTimeout(() => setLazyList(countriesListWithEmoji.slice(0, 7)), 500)
    }

    if (open) {
      setTimeout(() => setLazyList(countriesListWithEmoji), 0)
    }
  }, [open])

  return (
    <Box sx={{ height: 84, ...sx }}>
      <TextFieldStyledLabel>
        {label}{' '}
        {required && (
          <Box component={'span'} color={theme.palette.error.main}>
            *
          </Box>
        )}
      </TextFieldStyledLabel>

      <ReactInputMask
        onChange={changeHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={value}
        mask={getCountryMask() || ''}
        maskChar={null}
        disabled={!!disabled}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children={(props: any) => (
          <TextField
            className="phone-number"
            {...props}
            autoComplete="off"
            fullWidth={fullWidth}
            placeholder={placeholder}
            error={error}
            helperText={changerHelperText(helperText)}
            inputRef={ref}
            disabled={!!disabled}
            sx={{
              '& > div': {
                borderRadius: 2,
                pl: 0,
                borderColor: '#fff',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
              },
              '& fieldset': {
                borderWidth: '1px !important',
                transition: theme.transitions.create(['border-color'], {
                  duration: theme.transitions.duration.standard,
                }),
              },
              '& .Mui-focused': {
                backgroundColor: '#fff',
              },
              '& input': {
                padding: 0,
                fontWeight: 500,
                fontSize: { xs: 16, lg: 18 },
                lineHeight: { xs: '19px', lg: '21px' },
                transition: theme.transitions.create(['border-color'], {
                  duration: theme.transitions.duration.standard,
                }),
                '&::placeholder': {
                  fontWeight: 400,
                },
                '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus':
                  {
                    WebkitTextFillColor: 'currentColor',
                    WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                    transition: 'background-color 5000s ease-in-out 0s',
                  },
              },
              '& .Mui-focused fieldset, & .Mui-focused .border-right': {
                borderColor: '#032530' + '!important',
              },
              '& .border-right': {
                transition: theme.transitions.create(
                  ['border-color', 'background-color'],
                  {
                    duration: theme.transitions.duration.standard,
                  }
                ),
              },
              '&:hover .border-right': {
                borderColor: disabled
                  ? theme.palette.tailwindColors.neutral[400]
                  : theme.palette.tailwindColors.black,
              },
              '& .Mui-error .border-right': {
                borderColor: theme.palette.tailwindColors.red[600],
              },
            }}
            InputProps={{
              ref: anchorRef,
              id: 'composition-button',
              // ariaControls: open ? 'composition-menu' : undefined,
              // ariaExpanded: open ? 'true' : undefined,
              // ariaHaspopup: 'true',
              startAdornment: (
                <Box
                  sx={{
                    mr: 1.5,
                  }}
                >
                  <Box>
                    <ButtonBase
                      className="border-right"
                      onClick={() => {
                        !disabled && handleToggle()
                      }}
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: 16, lg: 18 },
                        lineHeight: { xs: '24px', lg: '28px' },
                        letterSpacing: '1px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        pr: 1,
                        pl: 2.5,
                        py: 2,
                        borderRight: '1px solid',
                        borderColor: theme.palette.tailwindColors.neutral[400],
                        height: '100%',
                        borderRadius: '8px 0 0 8px',
                        perspective: '10000px',
                        '&:focus': {
                          backgroundColor: '#E6E6E6',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          maxHeight: 22,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <StyledEmojiSpan>{country?.flagEmoji}</StyledEmojiSpan>
                      </Box>

                      <Typography
                        className="country-code"
                        sx={{
                          ml: 0.5,
                          fontSize: { xs: 16, lg: 18 },
                          lineHeight: { xs: '24px', lg: '28px' },
                          fontWeight: 500,
                        }}
                      >
                        {country?.code}
                      </Typography>

                      <KeyboardArrowDownIcon
                        sx={{
                          fontSize: 24,
                          color: '#032530',
                          transition: theme.transitions.create(['transform'], {
                            duration: theme.transitions.duration.standard,
                          }),
                          transform: open ? 'rotateX(180deg)' : 'rotateX(0deg)',
                        }}
                      />
                    </ButtonBase>

                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      keepMounted
                      // disablePortal
                      sx={{
                        zIndex: 1400,
                      }}
                    >
                      {({ TransitionProps /* , placement */ }) => (
                        <Grow
                          {...TransitionProps}
                          style={
                            {
                              // transformOrigin:
                              // placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }
                          }
                        >
                          <Paper
                            sx={{
                              width: anchorRef.current?.offsetWidth,
                              p: 0.5,
                              borderRadius: 2,
                              border: '1px solid #032530',
                              mt: '2px',
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <>
                                <SearchField
                                  originalArray={lazyList}
                                  setVisibleArray={setVisibleCountryList}
                                  setOpen={setOpen}
                                  handleClose={handleClose}
                                />

                                <MenuList
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                  sx={{
                                    p: 0.5,
                                    maxHeight: 280,
                                    overflowY: 'scroll',
                                    '::-webkit-scrollbar': {
                                      width: 6,
                                    },

                                    /* Track */
                                    '::-webkit-scrollbar-track': {
                                      [theme.breakpoints.up('lg')]: {
                                        background: '#EBEBEB',
                                        borderRadius: '100px',
                                      },
                                    },

                                    /* Handle */
                                    '::-webkit-scrollbar-thumb': {
                                      borderRadius: '100px',
                                      background: '#A5A5A5',
                                      width: '6px',
                                      height: '48px',
                                    },
                                  }}
                                >
                                  {_.map(
                                    visibleCountryList,
                                    (element, index) => (
                                      <MenuItem
                                        sx={{
                                          px: 1.5,
                                          py: 2,
                                          pr: 4,
                                          m: 0,
                                          minHeight: 'unset',
                                          fontWeight: 500,
                                          fontSize: 16,
                                          lineHeight: '19px',
                                          letterSpacing: '1px',
                                          borderRadius: 1,
                                          transition: theme.transitions.create(
                                            ['background-color'],
                                            {
                                              duration:
                                                theme.transitions.duration
                                                  .standard,
                                            }
                                          ),
                                          '&:hover': {
                                            backgroundColor: '#F2F2F2',
                                          },
                                        }}
                                        key={index}
                                        onClick={() =>
                                          handleChooseCountry(element)
                                        }
                                      >
                                        <Grid2
                                          sx={{ width: '100%' }}
                                          container
                                          direction="row"
                                        >
                                          <Grid2 xs={4}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px',
                                              }}
                                            >
                                              <StyledEmojiSpan>
                                                {element?.flagEmoji}
                                              </StyledEmojiSpan>

                                              <Box>{element?.code}</Box>
                                            </Box>
                                          </Grid2>

                                          <Grid2 xs={8}>
                                            <Box sx={{ overflow: 'hidden' }}>
                                              {element?.name}
                                            </Box>
                                          </Grid2>
                                        </Grid2>
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Box>
                </Box>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}

export const UiPhoneNumberField = React.forwardRef(UiPhoneNumberFieldWithRef)
