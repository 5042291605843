import {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  areThereKeycloakCredentials,
} from '@shared/constants'

import { TAuthHostService, configs } from '../../../app-settings'
import { deleteCookie, formEncode, getCookie, setCookie } from '../../../utils'
import { getUserAdditionalInfo, getUserRequest, logoutRequest } from '../api'
import { __CHALLENGE, __REFRESH_TOKEN, __TOKEN } from '../constants'
import { useAuthStore } from '../store'
import { generateVerifier, startLoginProcess } from '../utils'

export function useAuth() {
  const authStore = useAuthStore()

  if (!areThereKeycloakCredentials) {
    deleteCookie(__CHALLENGE)

    throw new Error('Keycloak credentials not provided')
  }

  const getTokensFromCookies = () => {
    return {
      token: getCookie(__TOKEN),
      refreshToken: getCookie(__REFRESH_TOKEN),
    }
  }

  const fetchUser = async () => {
    authStore.setInProcess(true)

    const response = await getUserRequest()

    if (response) {
      authStore.setUser(response)
    }

    authStore.setInProcess(false)

    return response
  }

  const fetchUserDetailInfo = async () => {
    const response = await getUserAdditionalInfo()

    if (response) {
      authStore.setUserDetailInfo(response)
    }

    return response
  }

  const startLogin = (type?: TAuthHostService) => {
    startLoginProcess(authStore.user, type)
  }

  const logout = async () => {
    const { pathToLanding } = configs()

    if (!areThereKeycloakCredentials) {
      throw new Error('Keycloak credentials not provided')
    }

    const token = getCookie(__TOKEN)

    if (!token) {
      window.location.href = `${pathToLanding}`

      return
    }

    await logoutRequest()
  }

  const changePassword = () => {
    if (!areThereKeycloakCredentials) {
      throw new Error('Keycloak credentials not provided')
    }

    const { pathToWorkspace } = configs()
    const challenge = generateVerifier()

    // save the challenge code and verifier,
    // they will be needed when requesting tokens from keycloak
    setCookie(__CHALLENGE, JSON.stringify(challenge), 1)

    const parameters = formEncode({
      client_id: KEYCLOAK_CLIENT_ID,
      redirect_uri: `${pathToWorkspace}/callback`,
      response_mode: 'fragment',
      response_type: 'code',
      code_challenge: challenge.codeChallenge,
      code_challenge_method: 'S256',
      scope: 'openid',
      kc_action: 'UPDATE_PASSWORD',
    })

    // redirect to keycloak
    localStorage.setItem('preAuthPath', window.location.href)

    window.location.href = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/auth?${parameters}`

    return
  }

  return {
    store: authStore,
    fetchUser,
    logout,
    startLoginProcess: startLogin,
    changePassword,
    fetchUserDetailInfo,
    getTokensFromCookies,
    keycloakInfo: {
      KEYCLOAK_URL,
      KEYCLOAK_REALM,
      KEYCLOAK_CLIENT_ID,
    },
  }
}
