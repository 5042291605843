import BesteronLogo from './assets/Besteron.png'
import DectaLogo from './assets/decta.png'
import GlobalPaymentsLogo from './assets/globalpayments.png'
import OndatoLogo from './assets/ondato.png'
import SatchelLogo from './assets/satchel.png'
import SiaLogo from './assets/SIA.png'

export const partnersLogos = {
  besteron: BesteronLogo,
  decta: DectaLogo,
  sia: SiaLogo,
  satchel: SatchelLogo,
  ondato: OndatoLogo,
  globalPayments: GlobalPaymentsLogo,
}

export type TPartnersLogoChooseVariant = keyof typeof partnersLogos
