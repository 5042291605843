import {
  SxProps,
  Box,
  FormHelperText,
  Stack,
  InputProps,
  useTheme,
} from '@mui/material'
import {
  ChangeEvent,
  FocusEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
} from 'react'
import { RefCallBack } from 'react-hook-form'

import { StyledTextField, TextFieldStyledLabel } from '@shared/ui'

interface IProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onClick?: (event: MouseEvent<HTMLInputElement>) => void
  value?: string | null
  name?: string
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string | boolean
  fullWidth?: boolean
  sx?: SxProps
  ref?: RefCallBack | null | React.RefObject<HTMLInputElement>
  multiline?: boolean
  rows?: number
  disabled?: boolean
  InputProps?: Partial<InputProps>
  type?: HTMLInputTypeAttribute
  showMaxLength?: number
  size?: number
  required?: boolean
}

export const BaseField = forwardRef<HTMLInputElement, IProps>(
  function Named(props, ref) {
    const {
      label,
      sx,
      error,
      fullWidth,
      helperText,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      onClick,
      InputProps,
      placeholder,
      value,
      multiline = false,
      rows,
      disabled,
      name,
      type,
      showMaxLength,
      size,
      required,
    } = props

    const theme = useTheme()

    return (
      <Box sx={{ ...sx }}>
        <TextFieldStyledLabel>
          <>{label}</>

          {required && (
            <Box component={'span'} color={theme.palette.error.main}>
              *
            </Box>
          )}
        </TextFieldStyledLabel>

        <StyledTextField
          name={name}
          rows={rows}
          multiline={multiline}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          InputProps={InputProps}
          value={value}
          error={error}
          placeholder={placeholder}
          fullWidth={fullWidth}
          inputRef={ref}
          disabled={disabled}
          type={type}
          sx={{
            backgroundColor: disabled
              ? theme.palette.background.default
              : theme.palette.common.white,
          }}
          inputProps={{
            size: size,
          }}
        />

        <FormHelperText component={'div'} error={error}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Box>{helperText}</Box>
            {showMaxLength && <Box>{`${value?.length}/${showMaxLength}`}</Box>}
          </Stack>
        </FormHelperText>
      </Box>
    )
  }
)
