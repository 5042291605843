import { Stack } from '@mui/material'
import { useRouteError } from 'react-router-dom'

import { ErrorPage } from '@pages/error'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'

import { appBarHeight } from '@shared/constants'

import { StyledMain } from './styled'

export function ErrorLayout() {
  const error = useRouteError()

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return (
    <>
      <AppBar />

      <Stack
        sx={{
          mt: { lg: appBarHeight.lg, xs: appBarHeight.xs },
          minHeight: {
            xs: `calc(100vh - ${appBarHeight.xs})`,
            lg: `calc(100vh - ${appBarHeight.lg})`,
          },
        }}
      >
        <StyledMain>
          <ErrorPage />
        </StyledMain>

        <Footer />
      </Stack>
    </>
  )
}
