import { Typography, useTheme, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AwsLogo } from '../assets/aws-logo'
import { GoogleCloudLogo } from '../assets/google-cloud-logo'

export function PoweredByBlock() {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      spacing={{ xs: 2, lg: 3 }}
      sx={{
        width: 'fit-content',
        ml: { xs: 'none', lg: 'auto' },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 14, lg: 16 },
          fontWeight: 500,
          color: theme.palette.tailwindColors.white,
        }}
      >
        {t('footer.Powered by', { ns: 'components' })}
      </Typography>

      <Stack direction="row" spacing={2}>
        <AwsLogo />
        <GoogleCloudLogo />
      </Stack>
    </Stack>
  )
}
