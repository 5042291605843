import { styled, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& > div': {
    borderRadius: 8,
    padding: 8,
    minHeight: 56,
    [theme.breakpoints.up('lg')]: {
      minHeight: 60,
    },
  },
  '& input': {
    padding: 0,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    fontFeatureSettings: "'zero' on",
    paddingLeft: 12,
    paddingRight: 12,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '22px',
    },
    '&::placeholder': {
      fontWeight: 400,
    },
    '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus': {
      WebkitTextFillColor: 'currentColor',
      WebkitBoxShadow: '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
  '& fieldset': {
    borderWidth: '1px !important',
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  '& .MuiInputBase-root.Mui-focused': {
    backgroundColor: '#FFFFFF',
  },
  '& .Mui-focused fieldset': {
    borderColor: '#009EE0' + '!important',
  },
  '& textarea': {
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    fontFeatureSettings: "'zero' on",
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '22px',
    },
    '&::placeholder': {
      fontWeight: 400,
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
    marginRight: 0,
  },
}))
