import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const PhoneNumberVerificationRoute: RouteObject = {
  path: routePaths.phoneNumberVerification,
  lazy: async () => {
    const { PhoneNumberVerification } = await import('./ui')

    return { Component: PhoneNumberVerification }
  },
}
