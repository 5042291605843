import { CssBaseline } from '@mui/material'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { CookieConfirmation } from '@libs/ui'

import '@shared/i18n'

import { LandingThemeProvider, TimeLocaleProvider } from './providers'
import RouterViewer from './router'

export default function App() {
  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle="Arbipay"
        titleTemplate="%s - Arbipay"
      />

      <LandingThemeProvider>
        <TimeLocaleProvider>
          <CookieConfirmation />
          <CssBaseline />
          <ToastContainer position="bottom-right" />
          <RouterViewer />
        </TimeLocaleProvider>
      </LandingThemeProvider>
    </>
  )
}
