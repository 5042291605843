import { SxProps, useMediaQuery, useTheme, Stack, Link } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useRouteError } from 'react-router-dom'

import { fbLink, linkedInLink } from '@shared/constants'

import { ReactComponent as FbIcon } from '../assets/fb-icon.svg'
import { ReactComponent as LinkedInIcon } from '../assets/linked-in-icon.svg'

import { PageNotFound } from './components'
import {
  StyledStack,
  StyledBox,
  StyledTitleTypography,
  StyledButton,
  StyledSocialBox,
  StyledContainer,
  StyledTextTypography,
} from './styled'

interface IProps {
  sx?: SxProps
}

export function ErrorPage({ sx }: IProps) {
  const { t } = useTranslation('fsd')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const error = useRouteError()

  const isStatus404 = useMemo(
    () =>
      !!error &&
      typeof error === 'object' &&
      'status' in error &&
      error.status === 404,
    []
  )

  const handleClick = () => {
    navigate('/')
  }

  return (
    <StyledContainer maxWidth="xl" sx={sx}>
      <StyledStack
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'initial' }}
      >
        <StyledBox>
          <StyledTitleTypography>Oops!</StyledTitleTypography>
        </StyledBox>

        {isStatus404 ? (
          <PageNotFound />
        ) : (
          <StyledTextTypography isStatus404={isStatus404}>
            {t('landing.pages.error.Something went wrong')}
          </StyledTextTypography>
        )}

        {mdAndUp && (
          <StyledButton
            sx={{ mb: 4.5 }}
            variant="contained"
            onClick={handleClick}
          >
            {t('landing.pages.error.button')}
          </StyledButton>
        )}

        <Stack direction="row" spacing={3.5}>
          <Link href={fbLink} target="_blank">
            <StyledSocialBox>
              <FbIcon />
            </StyledSocialBox>
          </Link>

          <Link href={linkedInLink} target="_blank">
            <StyledSocialBox>
              <LinkedInIcon />
            </StyledSocialBox>
          </Link>
        </Stack>
      </StyledStack>

      {!mdAndUp && (
        <StyledButton
          variant="contained"
          fullWidth
          onClick={handleClick}
          sx={{ maxWidth: 328, mx: 'auto', mb: 10 }}
        >
          {t('landing.pages.error.button')}
        </StyledButton>
      )}
    </StyledContainer>
  )
}
