import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const RegistrationFinishRoute: RouteObject = {
  path: routePaths.registrationFinish,
  lazy: async () => {
    const { FinishStep } = await import('./ui')

    return { Component: FinishStep }
  },
}
