import { Typography, styled } from '@mui/material'

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '140%',
  '& .colored': {
    color: '#009EE0',
  },
  '& a': {
    fontStyle: 'italic',
    textDecorationLine: 'underline',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
    lineHeight: '20px',
    '& .colored': {
      lineHeight: '24px',
    },
  },
}))
