import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface ValidationPhoneNumberState {
  stringLength: number
  setStringLength: (stringLength: number) => void

  countryCode: string | null
  setCountryCode: (countryCode: string | null) => void
}

export const useValidationPhoneNumberStore =
  create<ValidationPhoneNumberState>()(
    devtools(
      (set) => ({
        stringLength: 12,
        setStringLength: (stringLength) => set(() => ({ stringLength })),

        countryCode: null,
        setCountryCode: (countryCode) => set(() => ({ countryCode })),
      }),
      { name: 'validationPhoneNumber' }
    )
  )
