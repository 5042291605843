import { Stack, SxProps } from '@mui/material'

import UiIconAppStoreBadgeSVG from './assets/app-store-badge.svg'
import UiIconGooglePlayBadgeSVG from './assets/google-play-badge.svg'
import { AppBadge } from './components/'

interface IProps {
  sx?: SxProps
}

export interface IMobileAppButton {
  src: string
  href: string
  alt: string
}

export function MobileAppButtons({ sx }: IProps) {
  const mobileAppButtons: IMobileAppButton[] = [
    {
      src: UiIconGooglePlayBadgeSVG,
      href: 'https://play.google.com/store/apps/details?id=com.thefintechlab.arbitas&gl=SK',
      alt: 'GooglePlay',
    },
    {
      src: UiIconAppStoreBadgeSVG,
      href: 'https://apps.apple.com/sk/app/arbitaspay/id1659004197',
      alt: 'AppStore',
    },
  ]

  return (
    <Stack direction="row" spacing={1.5} sx={{ ...sx }}>
      {mobileAppButtons.map((button, index) => (
        <AppBadge key={index} href={button.href}>
          <img src={button.src} alt={button.alt} />
        </AppBadge>
      ))}
    </Stack>
  )
}
