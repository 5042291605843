import { Stack, styled } from '@mui/material'

export const StyledContainerForStoreIconStack = styled(Stack)(({ theme }) => ({
  width: 44,
  height: 44,
  backgroundColor: '#DAE7EE',
  borderRadius: 100,
  [theme.breakpoints.up('md')]: {
    width: 56,
    height: 56,
  },
}))
