import { styled } from '@mui/material'

export const StyledMain = styled('main')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  color: '#22262B',
  backgroundColor: '#FAFAFA',
}))
