import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const ProhibitedCountriesPageRoute: RouteObject = {
  path: routePaths.prohibitedCountries,
  lazy: async () => {
    const { ProhibitedCountriesAndActivitiesPage } = await import('./ui')

    return { Component: ProhibitedCountriesAndActivitiesPage }
  },
}
