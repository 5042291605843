import { styled, Typography } from '@mui/material'

export const RegistrationSubtitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 15,
  lineHeight: 1.3,
  textAlign: 'center',
  color: theme.palette.tailwindColors.neutral[500],
  letterSpacing: '0.01em',
  maxWidth: 480,
  margin: '0 auto',
  marginTop: 12,
  [theme.breakpoints.up('md')]: {
    fontSize: 18,
  },
}))
