import { routePaths } from '@shared/constants'

export interface ILandingFooterMenuItem {
  title: string
  href: string
}

export interface ILandingFooterMenu {
  title: string
  list: ILandingFooterMenuItem[]
}

export const footerMenuList: ILandingFooterMenu[] = [
  {
    title: 'components:footer.menu.Legal information',
    list: [
      {
        title: 'components:footer.menu.Terms and Conditions',
        href: routePaths.termsAndConditions,
      },
      {
        title: 'components:footer.menu.Privacy policy',
        href: routePaths.privacyPolicy,
      },
      {
        title: 'components:footer.menu.Integrated management Policy',
        href: routePaths.integratedManagementPolicy,
      },
      {
        title: 'components:footer.menu.Rules of investigation o complains',
        href: routePaths.rulesOfInvestigationOfComplains,
      },
      {
        title: 'components:footer.menu.Prohibited countries and activities',
        href: routePaths.prohibitedCountries,
      },
      {
        title: 'components:footer.menu.Fees',
        href: routePaths.feesPage,
      },
      {
        title: 'components:footer.menu.Contacts',
        href: routePaths.contactsPage,
      },
    ],
  },
  {
    title: 'components:footer.menu.Accounts',
    list: [
      {
        title: 'components:footer.menu.Documents set for individuals',
        href: routePaths.documentsInfoPersonal,
      },
      {
        title: 'components:footer.menu.Documents set for business',
        href: routePaths.documentsInfoBusiness,
      },
      {
        title: 'components:footer.menu.Open new Arbipay account',
        href: routePaths.openNewArbipayAccount,
      },
    ],
  },
]
