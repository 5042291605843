import { createTheme, ThemeProvider } from '@mui/material'
import { ReactNode } from 'react'
import { paletteOptions } from './palette-options'
import {
  componentsTypographyOptions,
  typographyOptions,
} from './typography-options'

interface IProps {
  children: ReactNode
}

export type TTailwindColorGrid =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 678,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: typographyOptions,
  palette: paletteOptions,
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: 'transparent !important',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    ...componentsTypographyOptions,
  },
})

export function LandingThemeProvider({ children }: IProps) {
  return <ThemeProvider theme={theme} children={children} />
}
