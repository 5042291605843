import { styled } from '@mui/material'

import { StyledImage } from './styled-image'

export const LaptopImage = styled(StyledImage)(({ theme }) => ({
  width: '150%',
  left: '-30%',
  bottom: '-5%',
  [theme.breakpoints.up(431)]: {
    width: '140%',
    left: '-30%',
  },
  [theme.breakpoints.up(490)]: {
    width: '130%',
    left: '-30%',
  },
  [theme.breakpoints.up(520)]: {
    width: '120%',
    left: '-25%',
  },
  [theme.breakpoints.up(550)]: {
    width: '110%',
    left: '-20%',
  },
  [theme.breakpoints.up(600)]: {
    width: '175%',
    left: '-40%',
  },
  [theme.breakpoints.up(700)]: {
    width: '165%',
    left: '-35%',
  },
  [theme.breakpoints.up(800)]: {
    width: '150%',
    left: '-35%',
  },
  [theme.breakpoints.up(800)]: {
    width: '130%',
    left: '-25%',
  },
  [theme.breakpoints.up(984)]: {
    width: '120%',
    left: '-25%',
  },
  [theme.breakpoints.up(1120)]: {
    width: '110%',
    left: '-20%',
  },
  [theme.breakpoints.up(1199)]: {
    width: '175%',
    left: '-40%',
  },
}))
