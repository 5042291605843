import { styled } from '@mui/material'

import { TPartnersLogoChooseVariant, partnersLogos } from '../../model'

interface IProps {
  name: TPartnersLogoChooseVariant
}

export const PartnersLogoViewer = styled('div')<IProps>(({ name }) => ({
  height: 130,
  width: 260,
  backgroundImage: `url('${partnersLogos[name]}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  alignContent: 'center',
}))
