import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enGB, sk } from 'date-fns/locale'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function TimeLocaleProvider({ children }: PropsWithChildren<object>) {
  const {
    i18n: { language },
  } = useTranslation()

  const [adapterLocale, setAdapterLocale] = useState<typeof enGB>(enGB)

  useEffect(() => {
    if (language === 'sk') {
      setAdapterLocale(sk)
    } else {
      setAdapterLocale(enGB)
    }
  }, [language])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
    >
      {children}
    </LocalizationProvider>
  )
}
