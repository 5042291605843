/**
 * Delay async function
 * @param ms - The delay time `Number`
 * @returns Promise<void>
 */
export function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), ms)
  })
}

/**
 * Convert amount string to valid number
 * @param amount The amount string `String`
 * @returns The amount number `Number`
 */
export function convertToNumberAmount(amount: string): number {
  if (!amount) {
    return 0
  }

  const withOutSpace = amount.replaceAll(' ', '')
  const withOutBottomSlash = withOutSpace.replaceAll('_', '')

  const withOutDots = Number(withOutBottomSlash)
    ? withOutBottomSlash
    : withOutBottomSlash.replaceAll('.', '')

  return Number(withOutDots)
}

/**
 * Get random number with min and max
 * @param min The min value `Number`
 * @param max The max value `Number`
 * @returns The random number `Number`
 */
export function getRandom(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min
}

export function convertPhoneNumberToApi(phoneNumber: string): string {
  const convertedPhoneNumber = phoneNumber.match(/[+\d]/g)?.join('')

  if (convertedPhoneNumber) {
    return convertedPhoneNumber
  }

  return phoneNumber
}

export function createUniqItem<T>(item: T): T {
  return JSON.parse(JSON.stringify(item))
}

export function bgColors(idx = 0) {
  type TBgColor = 'light' | 'main' | 'dark'

  const bgColor: TBgColor[] = ['light', 'main', 'dark', 'light']
  const start = bgColor[idx % 3]
  const end = bgColor[(idx % 3) + 1]

  return { start, end }
}

export const hideSymbols = (text: string) => {
  return text.replace(/[^\s]/g, '*')
}

// function for converting base64 to file
export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()

  return new File([blob], fileName, { type: 'image/png' })
}

export function formatDateNumberTo(date: Date, pattern: string) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return pattern
    .replace('YYYY', year.toString())
    .replace('MM', month.toString().padStart(2, '0'))
    .replace('DD', day.toString().padStart(2, '0'))
}

export function subtractYears(date: Date, years: number) {
  date.setFullYear(date.getFullYear() - years)

  return date
}

export const getCorrectTimeNumber = (number: number) => {
  let conv = Math.round(number).toString()

  if (conv.length < 2) {
    conv = '0' + conv
  }

  return conv
}
