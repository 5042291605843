import { colors, PaletteOptions } from '@mui/material'
import { tailwindColors } from './tailwind-colors'

export const paletteOptions: PaletteOptions = {
  tailwindColors,
  common: {
    white: '#ffffff',
    black: '#22262B',
  },
  primary: {
    main: '#009EE0',
    light: '#E5F7FF',
    dark: '#008FCC',
    contrastText: colors.common.white,
  },
  secondary: {
    main: '#0155B7',
    light: '#99E0FF',
    dark: '#004766',
    contrastText: colors.common.white,
  },
  error: {
    main: '#EE1C2E',
    light: '#FF436D',
    dark: '#D60033',
  },
  warning: {
    main: '#FF5F00',
    light: '#FF9900',
    dark: '#C74A00',
  },
  info: {
    main: '#006B99',
    light: '#33C2FF',
    dark: '#002433',
  },
  success: {
    main: '#059739',
    light: '#4CD96B',
    dark: '#087C21',
  },
  text: {
    primary: '#22262B',
    secondary: '#525252',
    disabled: '#717070',
  },
  background: {
    default: '#FAFAFA',
    paper: '#FFF',
  },
  custom: {
    red: {
      main: '#e41740',
      dark: '#a50a36',
      accent: '#c4103b',
    },
    blue: {
      main: '#0155B7',
      dark: '#09366C',
      accent: '#09366C',
      light: '#009EE0',
      blocks: '#CCF0FF',
    },
    white: {
      main: '#fff',
      light: '#f3f4f6',
      accent: '#f6ebf5',
      smoke: '#F7F7F7',
    },
    grey: {
      main: '#717070',
      dark: '#212529',
      light: '#B8B7B7',
      text: '#484848',
    },
    greyBlue: {
      light: '#4A4E5C',
      lighter: '#F3F5F8',
    },
    text: {
      tertiary: '#A5A5A5',
    },
    blocks: {
      purple: '#E8F1FF',
      lightBlue: '#F2F7FF',
    },
    rose: {
      main: '#FFEBF0',
    },
  },
}
