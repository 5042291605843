// TODO Rewrite this component - move contacts form from widget.
import { zodResolver } from '@hookform/resolvers/zod'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ElementType, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import zod from 'zod'

import { convertPhoneNumberToApi } from '@libs/utils'

import { clientRequest } from '@entities/client-request'
import { routePaths } from '@shared/constants'
import {
  BaseField,
  UiPhoneNumberField,
  useValidationPhoneNumberStore,
} from '@shared/ui'

interface IForm {
  fullName: string
  phoneNumber: string
  email: string
  date: Date
  title: string
  description: string
  termsAndConditions: boolean
  privacyPolicy: boolean
  captcha?: string | null
}

const StyledInputLabel = styled(Grid)(() => ({
  padding: 0,
  fontSize: 14,
  lineHeight: '20px',
  color: '#525252',
  fontFeatureSettings: "'zero' on",
}))

const StyledOutlinedInput = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    minHeight: 56,
    [theme.breakpoints.up('lg')]: {
      minHeight: 60,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#009EE0',
    },
    input: {
      padding: '12.5px 14px !important',
    },
  },
  '& .MuiButtonBase-root': {
    marginRight: 0,
  },
  '& fieldset': {
    borderWidth: '1px !important',
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.standard,
    }),
  },
}))

const StyledTitleH4 = styled('h4')(({ theme }) => ({
  margin: 0,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '32px',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontWeight: 700,
    fontSize: 28,
  },
}))

export function ContactUsForm() {
  const [countryCode, setCountryCode] = useState<string>('')
  const { stringLength } = useValidationPhoneNumberStore((state) => state)

  const validationsSchemas = zod.object({
    fullName: zod.string().min(1).trim(),
    phoneNumber: zod.string().min(stringLength),
    email: zod.string().email(),
    date: zod.date().min(new Date()),
    title: zod.string(),
    description: zod.string(),
  })

  const { control, handleSubmit, watch, setValue, getValues } = useForm<IForm>({
    mode: 'all',
    resolver: zodResolver(validationsSchemas),
  })

  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('pages')
  const phoneNumber = getValues('phoneNumber')

  const lgAndAp = useMediaQuery(theme.breakpoints.up('lg'))
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'))

  const checkTermsAndConditions = watch('termsAndConditions')
  const checkPrivacyPolicy = watch('privacyPolicy')
  const checkCaptcha = watch('captcha')

  useEffect(() => {
    if (!import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY) {
      // eslint-disable-next-line no-console
      console.error('ReCAPTCHA key not found!')
    }
  }, [])

  const onSubmit = async (form: IForm) => {
    try {
      const status = await clientRequest({
        date: form.date.toString(),
        email: form.email,
        name: form.fullName,
        title: form.title,
        phone: convertPhoneNumberToApi(`${countryCode}${phoneNumber}`),
        descriptions: form.description,
      })

      if (status) {
        toast.info(t('landing.contacts.Message send'))

        navigate('/')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={{ xs: 1.5, lg: 2.5 }}
    >
      <StyledTitleH4>
        {t('landing.contacts.Customer information')}
      </StyledTitleH4>

      <Controller
        control={control}
        name="fullName"
        render={({ field, fieldState }) => (
          <BaseField
            {...field}
            fullWidth
            placeholder={t('landing.contacts.Name')}
            error={!!fieldState.error}
            label={t('landing.contacts.Full Name/Company name')}
            helperText={!!fieldState.error && fieldState.error.message}
            sx={{
              height: 84,
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="phoneNumber"
        render={({ field, fieldState }) => (
          <UiPhoneNumberField
            {...field}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            fullWidth
            onCountryChange={setCountryCode}
            label={t('auth.signup.form.Phone number')}
            placeholder={t('auth.signup.form.Enter your phone number')}
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <BaseField
            {...field}
            fullWidth
            label="Email"
            placeholder="example@mail.com"
            error={!!fieldState.error}
            helperText={!!fieldState.error && fieldState.error.message}
            sx={{
              height: 84,
            }}
          />
        )}
      />

      <StyledTitleH4>{t('landing.contacts.Request')}</StyledTitleH4>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          control={control}
          name="date"
          render={({ field, fieldState }) => (
            <>
              {lgAndAp ? (
                <>
                  <StyledInputLabel>
                    {t('landing.contacts.Date')}
                  </StyledInputLabel>

                  <DesktopDatePicker
                    {...field}
                    slots={{
                      textField:
                        StyledOutlinedInput as ElementType<TextFieldProps>,
                    }}
                    slotProps={{
                      textField: {
                        ...field,
                        variant: 'outlined',
                        fullWidth: true,
                        label: t(
                          'workspace.business account application.pages.general.Date of registration'
                        ),
                        placeholder: t(
                          'workspace.business account application.pages.general.Date format'
                        ),
                        error: fieldState.invalid,
                        helperText: fieldState.error?.message,
                        sx: {
                          mt: '0 !important',
                        },
                      },
                    }}
                  />
                </>
              ) : (
                <MobileDatePicker
                  {...field}
                  slots={{
                    textField:
                      StyledOutlinedInput as ElementType<TextFieldProps>,
                  }}
                  slotProps={{
                    textField: {
                      ...field,
                      variant: 'outlined',
                      fullWidth: true,
                      label: t(
                        'workspace.business account application.pages.general.Date of registration'
                      ),
                      placeholder: t(
                        'workspace.business account application.pages.general.Date format'
                      ),
                      error: fieldState.invalid,
                      helperText: fieldState.error?.message,
                    },
                  }}
                />
              )}
            </>
          )}
        />
      </LocalizationProvider>

      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <BaseField
            {...field}
            fullWidth
            label={t('landing.contacts.Title')}
            placeholder={t('landing.contacts.Title')}
            error={!!fieldState.error}
            helperText={!!fieldState.error && fieldState.error.message}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <BaseField
            {...field}
            fullWidth
            multiline
            rows={4}
            label={t('landing.contacts.Descriptions')}
            placeholder={t('landing.contacts.Message')}
            error={!!fieldState.error}
            helperText={!!fieldState.error && fieldState.error.message}
          />
        )}
      />

      <Controller
        control={control}
        name="termsAndConditions"
        render={({ field }) => (
          <FormControlLabel
            {...field}
            control={<Checkbox checked={!!field.value} {...field} />}
            label={
              <Typography>
                {t(
                  'landing.contacts.By submitting this form, I agree with the Arbipay'
                )}
                &nbsp;
                <Link
                  href={routePaths.termsAndConditions}
                  target={'_blank'}
                  sx={{
                    color: theme.palette.custom.grey.dark,
                    fontWeight: 600,
                  }}
                >
                  {t('landing.contacts.Terms & conditions')}
                </Link>
              </Typography>
            }
          />
        )}
      />

      <Controller
        control={control}
        name="privacyPolicy"
        render={({ field }) => (
          <FormControlLabel
            {...field}
            control={<Checkbox checked={!!field.value} {...field} />}
            label={
              <Typography>
                {t(
                  'landing.contacts.By submitting this form, I agree with the Arbipay'
                )}
                &nbsp;
                <Link
                  href={routePaths.privacyPolicy}
                  target={'_blank'}
                  sx={{
                    color: theme.palette.custom.grey.dark,
                    fontWeight: 600,
                  }}
                >
                  {t('landing.contacts.Privacy policy')}
                </Link>
              </Typography>
            }
          />
        )}
      />

      <Stack alignItems="center" spacing={1}>
        <Typography>{t('landing.contacts.Captcha text')}</Typography>

        <ReCAPTCHA
          sitekey={import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY || ''}
          onChange={(e: string | null) => {
            setValue('captcha', e)
          }}
        />
      </Stack>

      <Button
        type="submit"
        variant="contained"
        size="large"
        fullWidth={smAndDown}
        disabled={
          !checkTermsAndConditions || !checkPrivacyPolicy || !checkCaptcha
        }
        sx={{
          borderRadius: 2,
          backgroundColor: '#009EE0',
          padding: '16px 59px',
          textTransform: 'none',
        }}
      >
        {t('landing.contacts.Send')}
      </Button>
    </Stack>
  )
}
