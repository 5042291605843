import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { StyledBoxDivider } from './styled'

interface IProps {
  sx?: SxProps
}

export function RegistrationToolbar({ sx }: IProps) {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box sx={sx}>
      <Typography variant="text28" component="h1" textAlign="center">
        {t('auth.signup.Registration process')}
      </Typography>

      <StyledBoxDivider />

      <Typography
        variant={mdAndUp ? 'text18' : 'text16Medium'}
        textAlign="center"
      >
        {t('auth.signup.Go through all steps of the registration process')}
      </Typography>
    </Box>
  )
}
