import { styled } from '@mui/material'
import AppBar from '@mui/material/AppBar'

import { appBarHeight } from '@shared/constants'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: '100%',
  height: appBarHeight.xs,
  backgroundColor: theme.palette.custom.white.main,
  justifyContent: 'center',
  boxShadow: '0px 2px 8px rgba(0, 0, 1, 0.1)',
  [theme.breakpoints.up('lg')]: {
    height: appBarHeight.lg,
  },
}))
