import { Paper, styled } from '@mui/material'

export const StyledAutocompleteDropdownPaper = styled(Paper)(({ theme }) => ({
  marginTop: '1px',
  marginBottom: '1px',
  border: '1px solid #009EE0',
  padding: theme.spacing(0.75),
  borderRadius: theme.spacing(1),
  '& ul': {
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'overlay',

    borderRadius: theme.spacing(1),
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: 'transparent',
    },
    '&::-webkit-scrollbar-track-piece': {
      marginTop: '5px',
      marginBottom: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: 6,
      height: 6,
      borderRadius: '100px',
      background: '#A5A5A5',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      visibility: 'visible',
      background: '#A5A5A5',
      width: 6,
      height: 6,
    },
    '& li': {
      borderRadius: theme.spacing(1),
      border: '1px solid transparent',
      paddingLeft: '24px !important',
      paddingRight: '24px !important',
      paddingTop: '18px !important',
      paddingBottom: '18px !important',
      fontWeight: 500,
      fontSize: { xs: 16, md: 18 },
      '&:hover': {
        background: '#F6FCFF !important',
      },
    },
  },
}))
