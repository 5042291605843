import { keyframes, styled } from '@mui/material'

const physicalCardAnimation = keyframes`
  0% {
    transform: unset;
  }
  25% {
    transform: unset;
  }
  50% {
    transform: translateX(20px) rotate(-30deg);
  }
  75% {
    transform: unset;
  }
  100% {
    transform: unset;
  }
`

export const StyledPhysicalCardImg = styled('img')(({ theme }) => ({
  animation: `${physicalCardAnimation} 5s infinite ease`,
  position: 'absolute',
  top: '42%',
  right: '20%',
  zIndex: 2,
  width: 170,
  [theme.breakpoints.up('md')]: {
    right: '12%',
    width: 'auto',
  },
}))
