import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface SessionState {
  sessionId: string
  nextTry: boolean
  setSessionId: (sessionId: string) => void
  setNextTry: (nextTry: boolean) => void
}

export const useSessionStore = create<SessionState>()(
  devtools(
    (set) => ({
      sessionId: '',
      nextTry: false,
      setNextTry: (nextTry) => set(() => ({ nextTry })),
      setSessionId: (sessionId) => set(() => ({ sessionId })),
    }),
    {
      name: 'session-storage',
    }
  )
)
