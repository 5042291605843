import { configs } from '../../../app-settings'
import { getCookie, errorHandler, apiErrorHandler } from '../../../utils'
import { __REFRESH_TOKEN, __TOKEN } from '../constants'
import { instanceApi } from './api-instance'

export async function logoutRequest() {
  const { pathToLanding, getHostService } = configs()
  const service = getHostService()

  try {
    const token = getCookie(__TOKEN)
    const refreshToken = getCookie(__REFRESH_TOKEN)?.replace('Bearer ', '')

    if (!token) {
      throw new Error('Unauthorized')
    }

    const url = `/user/logout`

    const response = await instanceApi.post<{ result: boolean }>(
      url,
      { refresh_token: refreshToken },
      { headers: { Authorization: token } }
    )

    if (response.data && !response.data?.result) {
      errorHandler('Logout error', 'error')
    }

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    apiErrorHandler(error)
  } finally {
    window.location.href = `${pathToLanding}/logout-callback?service=${service}`
  }
}
