import { keyframes, styled } from '@mui/material'

const virtualCardAnimation = keyframes`
  0% {
    transform: unset;
  }
  25% {
    transform: unset;
    top: 21%;
  }
  50% {
    transform: translateX(-20px) rotate(-10deg);
    top: 10%;
  }
  75% {
    transform: unset;
    top: 21%;
  }
  100% {
    transform: unset;
  }
`

export const StyledVirtualCardImg = styled('img')(({ theme }) => ({
  animation: `${virtualCardAnimation} 5s infinite ease`,
  position: 'absolute',
  top: '21%',
  left: '20%',
  zIndex: 1,
  width: 170,
  [theme.breakpoints.up('md')]: {
    left: '12%',
    width: 'auto',
  },
}))
