import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Stack,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LanguageSwitcher } from '@features/language-switcher'

import { routePaths } from '@shared/constants'
import { AppLink } from '@shared/ui'

import { IMenuItem } from '../app-bar'
import { MenuButton, StyledButton } from '../styled'

export function DesktopMenu() {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [focusedMenuItem, setFocusedMenuItem] = useState<null | string>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('components')

  const startLoginProcess = () => {
    window.location.href = 'https://my.arbitaspay.com/#/login'
  }

  const pages: IMenuItem[] = [
    {
      name: t('appbar.menu.Open Account.Personal'),
      navigationLink: routePaths.personalAccountPage,
    },
    {
      name: t('appbar.menu.Open Account.Business'),
      navigationLink: routePaths.businessAccountPage,
    },
    {
      name: t('appbar.menu.Company'),
      navigationLink: routePaths.contactsPage,
    },
  ]

  const handleOpenPopperMenu =
    (item: string) => (event: React.MouseEvent<HTMLElement>) => {
      if (item !== focusedMenuItem && !isOpen) {
        setIsOpen(true)

        setFocusedMenuItem(item)

        setAnchorEl(event.currentTarget)
      }

      if (item !== focusedMenuItem && isOpen) {
        setIsOpen(false)

        setTimeout(setAnchorEl, 325, event.currentTarget)

        setTimeout(setFocusedMenuItem, 325, item)

        setTimeout(setIsOpen, 325, true)
      }

      if (item === focusedMenuItem) {
        handleClosePopperMenu()
      }
    }

  const handleClosePopperMenu = () => {
    setIsOpen(false)

    setTimeout(setAnchorEl, 200, null)

    setTimeout(setFocusedMenuItem, 200, null)
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={{ xs: 4.5, xl: 7.5 }}
        >
          {pages.map((page, index) => (
            <Box key={index} onClick={handleOpenPopperMenu(`${page.name}`)}>
              <AppLink
                sx={{ textDecoration: 'none !important' }}
                href={page.navigationLink || '#'}
              >
                <MenuButton
                  sx={{
                    color: theme.palette.custom.grey.dark,
                    fontWeight: 400,
                  }}
                >
                  <Box component="span">{page.name}</Box>

                  {page.submenu?.length &&
                    (isOpen && focusedMenuItem === `${page.name}` ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    ))}
                </MenuButton>
              </AppLink>

              {page.submenu?.length && anchorEl && (
                <Popper
                  open={focusedMenuItem === `${page.name}` && isOpen}
                  anchorEl={anchorEl}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal={false}
                  sx={{
                    zIndex: theme.zIndex.drawer + 1,
                    paddingTop: '9px',
                  }}
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: 'top',
                      }}
                    >
                      <Paper
                        sx={{
                          borderRadius: '0 0 8px 8px',
                          borderTop: `2px solid ${theme.palette.custom.blue.light}`,
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClosePopperMenu}>
                          <Box>{/*  TODO items list*/}</Box>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              )}
            </Box>
          ))}
        </Stack>
      </Box>

      <Box display={'flex'} alignItems={'center'}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box>
            <StyledButton onClick={startLoginProcess}>
              {t('appbar.Login')}
            </StyledButton>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <LanguageSwitcher />
          </Box>
        </Stack>
      </Box>
    </>
  )
}
