import { styled, Typography } from '@mui/material'

export const RegistrationTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '21px',
  textAlign: 'center',
  width: 'auto',
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    fontSize: 25,
    lineHeight: '120%',
  },
}))
