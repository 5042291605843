import { styled } from '@mui/material'

export const QontoStepIconRoot = styled('div')(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  width: 24,
  height: 24,
  borderRadius: '50%',
  border: '1px solid',
  borderColor: '#032530',
  alignItems: 'center',
  justifyContent: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.common.white,
    fontSize: 18,
  },
}))
