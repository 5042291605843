import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Box, ClickAwayListener, TextField, styled } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'

import { ICountryWithEmoji } from '@libs/constants'

interface IProps {
  originalArray: ICountryWithEmoji[]
  setVisibleArray: React.Dispatch<React.SetStateAction<ICountryWithEmoji[]>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: (event: Event | React.SyntheticEvent) => void
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  position: 'relative',
  right: 4,
  '& input': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(3),
    fontSize: 18,
    lineHeight: '22px',
    backgroundColor: '#fafafa',
    borderRadius: '8px 8px 0 0',
  },
  '& .MuiInputBase-root:hover.MuiInputBase-root::before': {
    borderColor: '#4A4E5C',
  },
  '& .MuiInputBase-root::after': {
    borderColor: '#032530',
  },
}))

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  top: -4,
  width: 'calc(100% + 8px)',
}))

const StyledIcon = styled(SearchOutlinedIcon)(() => ({
  color: '#22262B',
  position: 'absolute',
  left: 16,
  zIndex: 1,
}))

export function SearchField({
  originalArray,
  setVisibleArray,
  setOpen,
  handleClose,
}: IProps) {
  const [value, setValue] = useState('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  useEffect(() => {
    setVisibleArray(
      originalArray.filter(
        (country) =>
          country.code.includes(value) ||
          country.name.toLowerCase().includes(value.toLowerCase())
      )
    )
  }, [value, originalArray])

  useEffect(() => {
    setValue('')

    const search = window.document.querySelector('.search-field')
    const input = search?.querySelector('input')

    input?.focus()
  }, [originalArray])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledBox>
        <StyledIcon />

        <StyledTextField
          className="search-field"
          variant="standard"
          value={value}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder="Search"
          fullWidth
          autoComplete="off"
        />
      </StyledBox>
    </ClickAwayListener>
  )
}
