import { Link as MuiLink, SxProps, styled, Box } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import _ from 'lodash'
import { ReactNode } from 'react'
import { Link as RouterLink, To } from 'react-router-dom'

interface IProps {
  children?: ReactNode
  href: To
  target?: React.HTMLAttributeAnchorTarget
  sx?: SxProps
  hovered?: boolean
  component?: React.ElementType
}

export const StyledMuiLink = styled(MuiLink, {
  shouldForwardProp: (prop) => prop !== 'hovered',
})<{
  component?: ReactNode
  to?: To
  hovered?: boolean
}>(({ hovered }) => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: hovered ? 'underline' : 'none',
  },
}))

export function AppLink({
  href,
  children,
  target,
  sx,
  hovered = true,
  component,
}: IProps) {
  if (_.isObject(href) || href[0] !== 'h') {
    return (
      <Box
        component={component}
        sx={{
          '& > a': {
            textDecoration: 'none',
            '&:hover': {
              textDecoration: hovered ? 'underline' : 'none',
            },
            ...(sx as SystemStyleObject),
          },
        }}
      >
        <RouterLink target={target} children={children} to={href} />
      </Box>
    )
  }

  return (
    <StyledMuiLink
      hovered={hovered}
      sx={sx}
      target={target}
      children={children}
      href={href}
    />
  )
}
