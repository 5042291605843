import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const OpenNewArbipayAccountPageRoute: RouteObject = {
  path: routePaths.openNewArbipayAccount,
  lazy: async () => {
    const { OpenNewArbipayAccount } = await import('./ui')

    return { Component: OpenNewArbipayAccount }
  },
}
