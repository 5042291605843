import { StepIconProps } from '@mui/material'

import { QontoStepIconRoot } from './styled'

export function QontoStepIcon(props: StepIconProps) {
  const { completed, className } = props

  return (
    <QontoStepIconRoot
      sx={{
        backgroundColor: completed ? '#032530' : undefined,
      }}
      className={className}
    />
  )
}
