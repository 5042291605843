import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMobileMenu } from '@widgets/app-bar'

import { LanguageSwitcher } from '@features/language-switcher'

import { routePaths } from '@shared/constants'
import { AppLink } from '@shared/ui'

import { IMenuItem } from '../app-bar'
import { StyledButton, MenuButton } from '../styled'

export function MobileMenu() {
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  const [, setAnchorEl] = useState<HTMLElement | null>(null)
  const [focusedMenuItem, setFocusedMenuItem] = useState<null | string>(null)
  const { t } = useTranslation('components')
  const setShow = useMobileMenu((state) => state.setShow)

  const startLoginProcess = () => {
    window.location.href = 'https://my.arbitaspay.com/#/login'
  }

  const pages: IMenuItem[] = [
    {
      name: t('appbar.menu.Open Account.Personal'),
      navigationLink: routePaths.personalAccountPage,
    },
    {
      name: t('appbar.menu.Open Account.Business'),
      navigationLink: routePaths.businessAccountPage,
    },
    {
      name: t('appbar.menu.Company'),
      navigationLink: routePaths.contactsPage,
    },
  ]

  const handleOpenPopperMenu =
    (item: string) => (event: React.MouseEvent<HTMLElement>) => {
      if (item !== focusedMenuItem) {
        handleClosePopperMenu()
      }

      if (event.currentTarget) {
        setAnchorEl(event.currentTarget)

        setFocusedMenuItem(item)
      }
    }

  const handleClosePopperMenu = () => {
    setAnchorEl(null)

    setFocusedMenuItem(null)
  }

  return (
    <Box
      sx={{
        height: '100vh',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        padding="12px 16px 12px 16px"
        spacing={1}
      >
        <Box>
          <StyledButton onClick={startLoginProcess}>
            {t('appbar.Login', { ns: 'components' })}
          </StyledButton>
        </Box>

        <Box display="flex" alignItems="center" gap={'28px'}>
          {/* <CartButton /> */}
          <LanguageSwitcher
            buttonSize="small"
            sx={{
              '& button': {
                padding: 0,
              },
            }}
          />
        </Box>
      </Stack>

      <Divider
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.blue.light,
          opacity: 0.1,
          height: 2,
        })}
      />

      <Stack padding="24px 0">
        <List
          sx={{
            fontWeight: 500,
            padding: 0,
          }}
        >
          {pages.map((page, index) => (
            <ListItem
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                px: 3,
                borderBottom: '1px solid #009EE066',
              }}
            >
              <AppLink
                sx={{ textDecoration: 'none !important' }}
                href={page.navigationLink || '#'}
              >
                <MenuButton
                  startIcon={
                    page.icon && (
                      <page.icon
                        sx={{
                          fontSize: '31px !important',
                          fill: 'transparent',
                          marginRight: '20px',
                        }}
                      />
                    )
                  }
                  onClick={(e) => {
                    if (!page.submenu?.length) {
                      setShow(false)
                    } else {
                      focusedMenuItem === page.name
                        ? handleClosePopperMenu()
                        : handleOpenPopperMenu(`${page.name}`)(e)
                    }
                  }}
                  sx={(theme) => ({
                    color: theme.palette.custom.grey.dark,
                    textAlign: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    padding: 0,
                  })}
                >
                  <Typography
                    sx={{
                      flexGrow: smAndUp ? 0 : 1,
                      textAlign: 'left',
                      pt: 0.25,
                      color: theme.palette.custom.grey.dark,
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    {page.name}
                  </Typography>

                  {page.submenu?.length &&
                    (focusedMenuItem === `${page.name}` ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    ))}
                </MenuButton>
              </AppLink>

              {page?.submenu?.length && (
                <Collapse in={focusedMenuItem === page.name}>
                  {/*<MarketplaceItems*/}
                  {/*  closeSubmenu={() => {*/}
                  {/*    setMobileMenuShow(false)*/}
                  {/*    handleClosePopperMenu()*/}
                  {/*  }}*/}
                  {/*  items={page.submenu}*/}
                  {/*  cols={page?.key === 'openAccount' ? 1 : 2}*/}
                  {/*/>*/}
                </Collapse>
              )}
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  )
}
