import axios from 'axios'

import { __REFRESH_TOKEN, __TOKEN } from '@libs/auth'
import { getCookie, setCookie, formEncode, secondsToDays } from '@libs/utils'

const baseUrl = import.meta.env.VITE_APP_BASE_URL as string

const arbipayApi = axios.create({
  baseURL: baseUrl,
})

// TODO Rewrite these interceptors!!!

arbipayApi.interceptors.request.use(
  async (config) => {
    // Do something before request is sent

    config.baseURL = baseUrl

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

arbipayApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      !error ||
      !error.response ||
      (error.response && error.response.status > 401)
    ) {
      return Promise.reject(error || new Error('Bad request'))
    }

    const { response, config } = error
    const { status, ...payload } = response

    try {
      if (status === 401) {
        const {
          VITE_APP_KEYCLOAK_URL: keycloakUrl,
          VITE_APP_KEYCLOAK_REALM: keycloakRealm,
          VITE_APP_KEYCLOAK_CLIENT_ID: keycloakClientId,
        } = import.meta.env

        const refreshToken = getCookie('refreshToken')

        if (!refreshToken) {
          return Promise.reject({ status: 500, ...payload })
        }

        const url = `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`

        const body = formEncode({
          grant_type: 'refresh_token',
          client_id: keycloakClientId,
          refresh_token: refreshToken.split(' ')[1],
        })

        const resp = await axios.post(url, body, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })

        setCookie(
          __TOKEN,
          `Bearer ${response.data.access_token}`,
          secondsToDays(response.data.expires_in)
        )

        setCookie(
          __REFRESH_TOKEN,
          `Bearer ${response.data.refresh_token}`,
          secondsToDays(response.data.refresh_expires_in)
        )

        const secondRequestResponse = await axios.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: 'Bearer ' + resp.data.access_token,
          },
        })

        if (!secondRequestResponse) {
          return Promise.reject({ status: 500, ...payload })
        }

        return secondRequestResponse
      } else {
        return Promise.reject({ status: 500, ...payload })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return Promise.reject({ status: 500, ...payload })
    }
  }
)

export { arbipayApi }
