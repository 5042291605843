import { Button, styled } from '@mui/material'

export const LinkButton = styled(Button)(() => ({
  fontFamily: 'Simplon Norm Trial',
  borderColor: 'transparent',
  borderWidth: '0',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: '#009EE0',
    borderWidth: '0',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: '#009EE0',
    borderWidth: '0',
  },
}))
