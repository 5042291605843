import { Button, styled } from '@mui/material'

export const MenuButton = styled(Button)(() => ({
  fontFamily: 'Simplon Norm Trial',
  backgroundColor: 'transparent',
  fontSize: 16,
  fontWeight: 400,
  color: '#22262B',
  textTransform: 'unset',
}))
