export interface ICountryWithEmoji {
  name: string
  code: string
  iso: string
  flagEmoji: string
  mask: string | string[]
}

export interface ICountry {
  id: number
  name: string
  abbreviation: string
  allow_payment: boolean
  exist: boolean
  currency_codes: string[]
}

const firstCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

const orderMapCountries = new Map<string, number>()

firstCountries.forEach((item, index) => orderMapCountries.set(item, index))

// This list is used in base form inputs
const unsortedCountries: ICountry[] = [
  {
    id: 240,
    name: 'Aland Islands',
    abbreviation: 'AX',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 4,
    name: 'Albania',
    abbreviation: 'AL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 5,
    name: 'Algeria',
    abbreviation: 'DZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 9,
    name: 'Andorra',
    abbreviation: 'AD',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 11,
    name: 'Antigua and Barbuda',
    abbreviation: 'AG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 12,
    name: 'Argentina',
    abbreviation: 'AR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 13,
    name: 'Armenia',
    abbreviation: 'AM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 14,
    name: 'Aruba',
    abbreviation: 'AW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 1,
    name: 'Australia',
    abbreviation: 'AU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 2,
    name: 'Austria',
    abbreviation: 'AT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 3,
    name: 'Azerbaijan',
    abbreviation: 'AZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 19,
    name: 'Bahrain',
    abbreviation: 'BH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 20,
    name: 'Belarus',
    abbreviation: 'BY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 22,
    name: 'Belgium',
    abbreviation: 'BE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 21,
    name: 'Belize',
    abbreviation: 'BZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 24,
    name: 'Bermuda',
    abbreviation: 'BM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 35,
    name: 'Bhutan',
    abbreviation: 'BT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 27,
    name: 'Bonaire, Sint Eustatius and Saba',
    abbreviation: 'BQ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    abbreviation: 'BA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 30,
    name: 'Brazil',
    abbreviation: 'BR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 25,
    name: 'Bulgaria',
    abbreviation: 'BG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 134,
    name: 'Burma',
    abbreviation: 'MM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 85,
    name: 'Canada',
    abbreviation: 'CA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 233,
    name: 'Chile',
    abbreviation: 'CL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 91,
    name: 'China',
    abbreviation: 'CN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 93,
    name: 'Colombia',
    abbreviation: 'CO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 149,
    name: 'Cook Islands',
    abbreviation: 'CK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 99,
    name: 'Costa Rica',
    abbreviation: 'CR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 100,
    name: "Cote d'Ivoire",
    abbreviation: 'CI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 228,
    name: 'Croatia',
    abbreviation: 'HR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 103,
    name: 'Curacao',
    abbreviation: 'CW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 88,
    name: 'Cyprus',
    abbreviation: 'CY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 232,
    name: 'Czech Republic',
    abbreviation: 'CZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 61,
    name: 'Denmark',
    abbreviation: 'DK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 65,
    name: 'Dominican Republic',
    abbreviation: 'DO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 238,
    name: 'Ecuador',
    abbreviation: 'EC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 66,
    name: 'Egypt',
    abbreviation: 'EG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 241,
    name: 'El Salvador',
    abbreviation: 'SV',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 243,
    name: 'Estonia',
    abbreviation: 'EE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 223,
    name: 'Falkland Islands (Malvinas Islands)',
    abbreviation: 'FK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 219,
    name: 'Faroe Islands',
    abbreviation: 'FO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 222,
    name: 'Finland',
    abbreviation: 'FI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 224,
    name: 'France',
    abbreviation: 'FR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 225,
    name: 'French Guiana',
    abbreviation: 'GF',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 226,
    name: 'French Polynesia',
    abbreviation: 'PF',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 59,
    name: 'Georgia',
    abbreviation: 'GE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 51,
    name: 'Germany',
    abbreviation: 'DE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 53,
    name: 'Gibraltar',
    abbreviation: 'GI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 58,
    name: 'Greece',
    abbreviation: 'GR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 57,
    name: 'Greenland',
    abbreviation: 'GL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 56,
    name: 'Grenada',
    abbreviation: 'GD',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 47,
    name: 'Guadeloupe',
    abbreviation: 'GP',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 48,
    name: 'Guatemala',
    abbreviation: 'GT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 52,
    name: 'Guernsey',
    abbreviation: 'GG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 55,
    name: 'Hong Kong',
    abbreviation: 'HK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 37,
    name: 'Hungary',
    abbreviation: 'HU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 77,
    name: 'Iceland',
    abbreviation: 'IS',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 71,
    name: 'India',
    abbreviation: 'IN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 72,
    name: 'Indonesia',
    abbreviation: 'ID',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 76,
    name: 'Ireland',
    abbreviation: 'IE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 152,
    name: 'Isle of Man',
    abbreviation: 'IM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 70,
    name: 'Israel',
    abbreviation: 'IL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 79,
    name: 'Italy',
    abbreviation: 'IT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 250,
    name: 'Japan',
    abbreviation: 'JP',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 62,
    name: 'Jersey',
    abbreviation: 'JE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 73,
    name: 'Jordan',
    abbreviation: 'JO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 82,
    name: 'Kazakhstan',
    abbreviation: 'KZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 87,
    name: 'Kenya',
    abbreviation: 'KE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 98,
    name: 'Korea, Republic of',
    abbreviation: 'KR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 102,
    name: 'Kuwait',
    abbreviation: 'KW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 105,
    name: 'Latvia',
    abbreviation: 'LV',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 111,
    name: 'Liechtenstein',
    abbreviation: 'LI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 110,
    name: 'Lithuania',
    abbreviation: 'LT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 112,
    name: 'Luxembourg',
    abbreviation: 'LU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 117,
    name: 'Macao',
    abbreviation: 'MO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 119,
    name: 'Malaysia',
    abbreviation: 'MY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 123,
    name: 'Malta',
    abbreviation: 'MT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 126,
    name: 'Marshall Islands',
    abbreviation: 'MH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 125,
    name: 'Martinique',
    abbreviation: 'MQ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 116,
    name: 'Mayotte',
    abbreviation: 'YT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 127,
    name: 'Mexico',
    abbreviation: 'MX',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 130,
    name: 'Moldova',
    abbreviation: 'MD',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 131,
    name: 'Monaco',
    abbreviation: 'MC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 231,
    name: 'Montenegro',
    abbreviation: 'ME',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 124,
    name: 'Morocco',
    abbreviation: 'MA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 137,
    name: 'Nepal',
    abbreviation: 'NP',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 140,
    name: 'Netherlands',
    abbreviation: 'NL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 143,
    name: 'New Zealand',
    abbreviation: 'NZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 168,
    name: 'North Macedonia',
    abbreviation: 'MK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 145,
    name: 'Norway',
    abbreviation: 'NO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 147,
    name: 'Oman',
    abbreviation: 'OM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 158,
    name: 'Palestinian National Authority',
    abbreviation: 'PS',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 162,
    name: 'Paraguay',
    abbreviation: 'PY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 163,
    name: 'Peru',
    abbreviation: 'PE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 221,
    name: 'Philippines',
    abbreviation: 'PH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 165,
    name: 'Poland',
    abbreviation: 'PL',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 166,
    name: 'Portugal',
    abbreviation: 'PT',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 167,
    name: 'Puerto Rico',
    abbreviation: 'PR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 86,
    name: 'Qatar',
    abbreviation: 'QA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 169,
    name: 'Reunion',
    abbreviation: 'RE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 172,
    name: 'Romania',
    abbreviation: 'RO',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 170,
    name: 'Russian Federation',
    abbreviation: 'RU',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 185,
    name: 'Saint Kitts and Nevis',
    abbreviation: 'KN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 186,
    name: 'Saint Lucia',
    abbreviation: 'LC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 183,
    name: 'Saint Martin (French Part)',
    abbreviation: 'MF',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 184,
    name: 'Saint Vincent and the Grenadines',
    abbreviation: 'VC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 174,
    name: 'San Marino',
    abbreviation: 'SM',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 176,
    name: 'Saudi Arabia',
    abbreviation: 'SA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 188,
    name: 'Serbia',
    abbreviation: 'RS',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 180,
    name: 'Seychelles',
    abbreviation: 'SC',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 189,
    name: 'Singapore',
    abbreviation: 'SG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 190,
    name: 'Sint Maarten',
    abbreviation: 'SX',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 192,
    name: 'Slovakia',
    abbreviation: 'SK',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 193,
    name: 'Slovenia',
    abbreviation: 'SI',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 196,
    name: 'Solomon Islands',
    abbreviation: 'SB',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 245,
    name: 'South Africa',
    abbreviation: 'ZA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 78,
    name: 'Spain',
    abbreviation: 'ES',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 109,
    name: 'State of Libya',
    abbreviation: 'LY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 199,
    name: 'Suriname',
    abbreviation: 'SR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 235,
    name: 'Sweden',
    abbreviation: 'SE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 234,
    name: 'Switzerland',
    abbreviation: 'CH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 203,
    name: 'Taiwan, Province of China',
    abbreviation: 'TW',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 202,
    name: 'Thailand',
    abbreviation: 'TH',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 211,
    name: 'Tunisia',
    abbreviation: 'TN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 213,
    name: 'Turkey',
    abbreviation: 'TR',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 216,
    name: 'Ukraine',
    abbreviation: 'UA',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 146,
    name: 'United Arab Emirates',
    abbreviation: 'AE',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 194,
    name: 'United Kingdom',
    abbreviation: 'GB',
    allow_payment: true,
    exist: true,
    currency_codes: ['GBP'],
  },
  {
    id: 195,
    name: 'United States',
    abbreviation: 'US',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 218,
    name: 'Uruguay',
    abbreviation: 'UY',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 215,
    name: 'Uzbekistan',
    abbreviation: 'UZ',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 41,
    name: 'Vietnam',
    abbreviation: 'VN',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
  {
    id: 39,
    name: 'Virgin Islands, British',
    abbreviation: 'VG',
    allow_payment: true,
    exist: true,
    currency_codes: [],
  },
]

export const countries = unsortedCountries.sort((a, b) => {
  const indexA = (
    orderMapCountries.has(a.abbreviation)
      ? orderMapCountries.get(a.abbreviation)
      : Infinity
  ) as number

  const indexB = (
    orderMapCountries.has(b.abbreviation)
      ? orderMapCountries.get(b.abbreviation)
      : Infinity
  ) as number

  return indexA - indexB
})

export const countriesAbbreviation = countries
  .map((item) => {
    if (item.abbreviation) {
      return item.abbreviation
    }
  })
  .filter(Boolean) as string[]

export type TCountry = (typeof countries)[number]['abbreviation']

// This list is used in phone inputs
const unsortedCountriesListWithEmoji: ICountryWithEmoji[] = [
  {
    name: 'Afghanistan',
    code: '+93',
    iso: 'AF',
    flagEmoji: '🇦🇫',
    mask: '99-999-9999',
  },
  {
    name: 'Aland Islands',
    code: '+358',
    iso: 'AX',
    flagEmoji: '🇦🇽',
    mask: '999-999-99-99',
  },
  {
    name: 'Albania',
    code: '+355',
    iso: 'AL',
    flagEmoji: '🇦🇱',
    mask: '999-999-999',
  },
  {
    name: 'Algeria',
    code: '+213',
    iso: 'DZ',
    flagEmoji: '🇩🇿',
    mask: '99-999-9999',
  },
  {
    name: 'American Samoa',
    code: '+1',
    iso: 'AS',
    flagEmoji: '🇦🇸',
    mask: '684-999-9999',
  },
  {
    name: 'Andorra',
    code: '+376',
    iso: 'AD',
    flagEmoji: '🇦🇩',
    mask: '999-999',
  },
  {
    name: 'Angola',
    code: '+244',
    iso: 'AO',
    flagEmoji: '🇦🇴',
    mask: '999-999-999',
  },
  {
    name: 'Anguilla',
    code: '+1',
    iso: 'AI',
    flagEmoji: '🇦🇮',
    mask: '264-999-9999',
  },
  {
    name: 'Antarctica',
    code: '+672',
    iso: 'AQ',
    flagEmoji: '🇦🇶',
    mask: '199-999',
  },
  {
    name: 'Antigua and Barbuda',
    code: '+1',
    iso: 'AG',
    flagEmoji: '🇦🇬',
    mask: '268-999-9999',
  },
  {
    name: 'Argentina',
    code: '+54',
    iso: 'AR',
    flagEmoji: '🇦🇷',
    mask: '999-999-9999',
  },
  {
    name: 'Armenia',
    code: '+374',
    iso: 'AM',
    flagEmoji: '🇦🇲',
    mask: '99-999-999',
  },
  {
    name: 'Aruba',
    code: '+297',
    iso: 'AW',
    flagEmoji: '🇦🇼',
    mask: '999-9999',
  },
  {
    name: 'Ascension Island',
    code: '+247',
    iso: 'AC',
    flagEmoji: '🇦🇨',
    mask: '9999',
  },
  {
    name: 'Australia',
    code: '+61',
    iso: 'AU',
    flagEmoji: '🇦🇺',
    mask: '9-9999-9999',
  },
  {
    name: 'Austria',
    code: '+43',
    iso: 'AT',
    flagEmoji: '🇦🇹',
    mask: '999-999-9999',
  },
  {
    name: 'Azerbaijan',
    code: '+994',
    iso: 'AZ',
    flagEmoji: '🇦🇿',
    mask: '99-999-99-99',
  },
  {
    name: 'Bahamas',
    code: '+1',
    iso: 'BS',
    flagEmoji: '🇧🇸',
    mask: '242-999-9999',
  },
  {
    name: 'Bahrain',
    code: '+973',
    iso: 'BH',
    flagEmoji: '🇧🇭',
    mask: '9999-9999',
  },
  {
    name: 'Bangladesh',
    code: '+880',
    iso: 'BD',
    flagEmoji: '🇧🇩',
    mask: '1999-999999',
  },
  {
    name: 'Barbados',
    code: '+1',
    iso: 'BB',
    flagEmoji: '🇧🇧',
    mask: '246-999-9999',
  },
  {
    name: 'Belarus',
    code: '+375',
    iso: 'BY',
    flagEmoji: '🇧🇾',
    mask: '99-999-99-99',
  },
  {
    name: 'Belgium',
    code: '+32',
    iso: 'BE',
    flagEmoji: '🇧🇪',
    mask: '999-999-999',
  },
  {
    name: 'Belize',
    code: '+501',
    iso: 'BZ',
    flagEmoji: '🇧🇿',
    mask: '999-9999',
  },
  {
    name: 'Benin',
    code: '+229',
    iso: 'BJ',
    flagEmoji: '🇧🇯',
    mask: '99-99-9999',
  },
  {
    name: 'Bermuda',
    code: '+1',
    iso: 'BM',
    flagEmoji: '🇧🇲',
    mask: '441-999-9999',
  },
  {
    name: 'Bhutan',
    code: '+975',
    iso: 'BT',
    flagEmoji: '🇧🇹',
    mask: ['17-999-999', '77-999-999', '9-999-999'],
  },
  {
    name: 'Bolivia',
    code: '+591',
    iso: 'BO',
    flagEmoji: '🇧🇴',
    mask: '9-999-9999',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: '+387',
    iso: 'BA',
    flagEmoji: '🇧🇦',
    mask: ['99-9999', '99-99999'],
  },
  {
    name: 'Botswana',
    code: '+267',
    iso: 'BW',
    flagEmoji: '🇧🇼',
    mask: '99-999-999',
  },
  {
    name: 'Brazil',
    code: '+55',
    iso: 'BR',
    flagEmoji: '🇧🇷',
    mask: ['99-9999-9999', '99-99999-9999'],
  },
  {
    name: 'British Indian Ocean Territory',
    code: '+246',
    iso: 'IO',
    flagEmoji: '🇮🇴',
    mask: '999-9999',
  },
  {
    name: 'Brunei Darussalam',
    code: '+673',
    iso: 'BN',
    flagEmoji: '🇧🇳',
    mask: '999-9999',
  },
  {
    name: 'Bulgaria',
    code: '+359',
    iso: 'BG',
    flagEmoji: '🇧🇬',
    mask: '999-999-999',
  },
  {
    name: 'Burkina Faso',
    code: '+226',
    iso: 'BF',
    flagEmoji: '🇧🇫',
    mask: '99-99-9999',
  },
  {
    name: 'Burundi',
    code: '+257',
    iso: 'BI',
    flagEmoji: '🇧🇮',
    mask: '99-99-9999',
  },
  {
    name: 'Cambodia',
    code: '+855',
    iso: 'KH',
    flagEmoji: '🇰🇭',
    mask: '99-999-999',
  },
  {
    name: 'Cameroon',
    code: '+237',
    iso: 'CM',
    flagEmoji: '🇨🇲',
    mask: '9999-9999',
  },
  {
    name: 'Canada',
    code: '+1',
    iso: 'CA',
    flagEmoji: '🇨🇦',
    mask: '999-999-9999',
  },
  {
    name: 'Cape Verde',
    code: '+238',
    iso: 'CV',
    flagEmoji: '🇨🇻',
    mask: '999-99-99',
  },
  {
    name: 'Cayman Islands',
    code: '+1',
    iso: 'KY',
    flagEmoji: '🇰🇾',
    mask: '345-999-9999',
  },
  {
    name: 'Central African Republic',
    code: '+236',
    iso: 'CF',
    flagEmoji: '🇨🇫',
    mask: '99-99-9999',
  },
  {
    name: 'Chad',
    code: '+235',
    iso: 'TD',
    flagEmoji: '🇹🇩',
    mask: '99-99-99-99',
  },
  {
    name: 'Chile',
    code: '+56',
    iso: 'CL',
    flagEmoji: '🇨🇱',
    mask: '9-9999-9999',
  },
  {
    name: 'China',
    code: '+86',
    iso: 'CN',
    flagEmoji: '🇨🇳',
    mask: ['999-9999-999', '999-9999-9999', '99-99999-99999'],
  },
  {
    name: 'Christmas Island',
    code: '+61',
    iso: 'CX',
    flagEmoji: '🇨🇽',
    mask: '9-9999-9999',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: '+61',
    iso: 'CC',
    flagEmoji: '🇨🇨',
    mask: '9-9999-9999',
  },
  {
    name: 'Colombia',
    code: '+57',
    iso: 'CO',
    flagEmoji: '🇨🇴',
    mask: '999-999-9999',
  },
  {
    name: 'Comoros',
    code: '+269',
    iso: 'KM',
    flagEmoji: '🇰🇲',
    mask: '99-99999',
  },
  {
    name: 'Congo',
    code: '+242',
    iso: 'CG',
    flagEmoji: '🇨🇬',
    mask: '99-99999',
  },
  {
    name: 'Cook Islands',
    code: '+682',
    iso: 'CK',
    flagEmoji: '🇨🇰',
    mask: '99-999',
  },
  {
    name: 'Costa Rica',
    code: '+506',
    iso: 'CR',
    flagEmoji: '🇨🇷',
    mask: '9999-9999',
  },
  {
    name: 'Croatia',
    code: '+385',
    iso: 'HR',
    flagEmoji: '🇭🇷',
    mask: '99-999-999',
  },
  {
    name: 'Cuba',
    code: '+53',
    iso: 'CU',
    flagEmoji: '🇨🇺',
    mask: '9-999-9999',
  },
  {
    name: 'Cyprus',
    code: '+357',
    iso: 'CY',
    flagEmoji: '🇨🇾',
    mask: '99-999-999',
  },
  {
    name: 'Czech Republic',
    code: '+420',
    iso: 'CZ',
    flagEmoji: '🇨🇿',
    mask: '999-999-999',
  },
  {
    name: 'Democratic Republic of the Congo',
    code: '+243',
    iso: 'CD',
    flagEmoji: '🇨🇩',
    mask: '999-999-999',
  },
  {
    name: 'Denmark',
    code: '+45',
    iso: 'DK',
    flagEmoji: '🇩🇰',
    mask: '99-99-99-99',
  },
  {
    name: 'Djibouti',
    code: '+253',
    iso: 'DJ',
    flagEmoji: '🇩🇯',
    mask: '99-99-99-99',
  },
  {
    name: 'Dominica',
    code: '+1',
    iso: 'DM',
    flagEmoji: '🇩🇲',
    mask: '767-999-9999',
  },
  {
    name: 'Dominican Republic',
    code: '+1',
    iso: 'DO',
    flagEmoji: '🇩🇴',
    mask: ['809-999-9999', '829-999-9999', '849-999-9999'],
  },
  {
    name: 'Ecuador',
    code: '+593',
    iso: 'EC',
    flagEmoji: '🇪🇨',
    mask: ['9-999-9999', '99-999-9999'],
  },
  {
    name: 'Egypt',
    code: '+20',
    iso: 'EG',
    flagEmoji: '🇪🇬',
    mask: '999-999-9999',
  },
  {
    name: 'El Salvador',
    code: '+503',
    iso: 'SV',
    flagEmoji: '🇸🇻',
    mask: '99-99-9999',
  },
  {
    name: 'Equatorial Guinea',
    code: '+240',
    iso: 'GQ',
    flagEmoji: '🇬🇶',
    mask: '99-999-9999',
  },
  {
    name: 'Eritrea',
    code: '+291',
    iso: 'ER',
    flagEmoji: '🇪🇷',
    mask: '9-999-999',
  },
  {
    name: 'Estonia',
    code: '+372',
    iso: 'EE',
    flagEmoji: '🇪🇪',
    mask: ['999-9999', '9999-9999'],
  },
  {
    name: 'Eswatini',
    code: '+268',
    iso: 'SZ',
    flagEmoji: '🇸🇿',
    mask: '99-99-9999',
  },
  {
    name: 'Ethiopia',
    code: '+251',
    iso: 'ET',
    flagEmoji: '🇪🇹',
    mask: '99-999-9999',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: '+500',
    iso: 'FK',
    flagEmoji: '🇫🇰',
    mask: '99999',
  },
  {
    name: 'Faroe Islands',
    code: '+298',
    iso: 'FO',
    flagEmoji: '🇫🇴',
    mask: '999-999',
  },
  {
    name: 'Fiji',
    code: '+679',
    iso: 'FJ',
    flagEmoji: '🇫🇯',
    mask: '99-99999',
  },
  {
    name: 'Finland',
    code: '+358',
    iso: 'FI',
    flagEmoji: '🇫🇮',
    mask: '999-999-99-99',
  },
  {
    name: 'France',
    code: '+33',
    iso: 'FR',
    flagEmoji: '🇫🇷',
    mask: '999-999-999',
  },
  {
    name: 'French Guiana',
    code: '+594',
    iso: 'GF',
    flagEmoji: '🇬🇫',
    mask: '99999-9999',
  },
  {
    name: 'French Polynesia',
    code: '+689',
    iso: 'PF',
    flagEmoji: '🇵🇫',
    mask: '99-99-99',
  },
  {
    name: 'Gabon',
    code: '+241',
    iso: 'GA',
    flagEmoji: '🇬🇦',
    mask: '9-99-99-99',
  },
  {
    name: 'Gambia',
    code: '+220',
    iso: 'GM',
    flagEmoji: '🇬🇲',
    mask: '999-99-99',
  },
  {
    name: 'Georgia',
    code: '+995',
    iso: 'GE',
    flagEmoji: '🇬🇪',
    mask: '999-999-999',
  },
  {
    name: 'Germany',
    code: '+49',
    iso: 'DE',
    flagEmoji: '🇩🇪',
    mask: [
      '999-999',
      '999-99-99',
      '999-99-999',
      '999-99-9999',
      '999-999-9999',
      '9999-999-9999',
    ],
  },
  {
    name: 'Ghana',
    code: '+233',
    iso: 'GH',
    flagEmoji: '🇬🇭',
    mask: '999-999-999',
  },
  {
    name: 'Gibraltar',
    code: '+350',
    iso: 'GI',
    flagEmoji: '🇬🇮',
    mask: '999-99999',
  },
  {
    name: 'Greece',
    code: '+30',
    iso: 'GR',
    flagEmoji: '🇬🇷',
    mask: '999-999-9999',
  },
  {
    name: 'Greenland',
    code: '+299',
    iso: 'GL',
    flagEmoji: '🇬🇱',
    mask: '99-99-99',
  },
  {
    name: 'Grenada',
    code: '+1',
    iso: 'GD',
    flagEmoji: '🇬🇩',
    mask: '473-999-9999',
  },
  {
    name: 'Guadeloupe',
    code: '+590',
    iso: 'GP',
    flagEmoji: '🇬🇵',
    mask: '999-999-999',
  },
  {
    name: 'Guam',
    code: '+1',
    iso: 'GU',
    flagEmoji: '🇬🇺',
    mask: '671-999-9999',
  },
  {
    name: 'Guatemala',
    code: '+502',
    iso: 'GT',
    flagEmoji: '🇬🇹',
    mask: '9-999-9999',
  },
  {
    name: 'Guernsey',
    code: '+44',
    iso: 'GG',
    flagEmoji: '🇬🇬',
    mask: '9999-999999',
  },
  {
    name: 'Guinea',
    code: '+224',
    iso: 'GN',
    flagEmoji: '🇬🇳',
    mask: '99-999-999',
  },
  {
    name: 'Guinea-Bissau',
    code: '+245',
    iso: 'GW',
    flagEmoji: '🇬🇼',
    mask: '9-999999',
  },
  {
    name: 'Guyana',
    code: '+592',
    iso: 'GY',
    flagEmoji: '🇬🇾',
    mask: '999-9999',
  },
  {
    name: 'Haiti',
    code: '+509',
    iso: 'HT',
    flagEmoji: '🇭🇹',
    mask: '99-99-9999',
  },
  {
    name: 'Holy See (Vatican City State)',
    code: '+39',
    iso: 'VA',
    flagEmoji: '🇻🇦',
    mask: '06 69899999',
  },
  {
    name: 'Honduras',
    code: '+504',
    iso: 'HN',
    flagEmoji: '🇭🇳',
    mask: '9999-9999',
  },
  {
    name: 'Hong Kong',
    code: '+852',
    iso: 'HK',
    flagEmoji: '🇭🇰',
    mask: '9999-9999',
  },
  {
    name: 'Hungary',
    code: '+36',
    iso: 'HU',
    flagEmoji: '🇭🇺',
    mask: '999-999-999',
  },
  {
    name: 'Iceland',
    code: '+354',
    iso: 'IS',
    flagEmoji: '🇮🇸',
    mask: '999-9999',
  },
  {
    name: 'India',
    code: '+91',
    iso: 'IN',
    flagEmoji: '🇮🇳',
    mask: '9999-999-999',
  },
  {
    name: 'Indonesia',
    code: '+62',
    iso: 'ID',
    flagEmoji: '🇮🇩',
    mask: [
      '99-999-99',
      '99-999-999',
      '99-999-9999',
      '899-999-999',
      '899-999-99-999',
    ],
  },
  {
    name: 'Iran',
    code: '+98',
    iso: 'IR',
    flagEmoji: '🇮🇷',
    mask: '999-999-9999',
  },
  {
    name: 'Iraq',
    code: '+924',
    iso: 'IQ',
    flagEmoji: '🇮🇷',
    mask: '999-999-9999',
  },
  {
    name: 'Ireland',
    code: '+353',
    iso: 'IE',
    flagEmoji: '🇮🇪',
    mask: '999-999-999',
  },
  {
    name: 'Isle of Man',
    code: '+44',
    iso: 'IM',
    flagEmoji: '🇮🇲',
    mask: '9999-999999',
  },
  {
    name: 'Israel',
    code: '+972',
    iso: 'IL',
    flagEmoji: '🇮🇱',
    mask: ['9-999-9999', '59-999-9999'],
  },
  {
    name: 'Italy',
    code: '+39',
    iso: 'IT',
    flagEmoji: '🇮🇹',
    mask: '999-9999-999',
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    code: '+225',
    iso: 'CI',
    flagEmoji: '🇨🇮',
    mask: '99-999-999',
  },
  {
    name: 'Jamaica',
    code: '+1',
    iso: 'JM',
    flagEmoji: '🇯🇲',
    mask: '876-999-9999',
  },
  {
    name: 'Japan',
    code: '+81',
    iso: 'JP',
    flagEmoji: '🇯🇵',
    mask: ['999-999-999', '99-9999-9999'],
  },
  {
    name: 'Jersey',
    code: '+44',
    iso: 'JE',
    flagEmoji: '🇯🇪',
    mask: '9999-9999-999999',
  },
  {
    name: 'Jordan',
    code: '+962',
    iso: 'JO',
    flagEmoji: '🇯🇴',
    mask: '9-9999-9999',
  },
  {
    name: 'Kazakhstan',
    code: '+77',
    iso: 'KZ',
    flagEmoji: '🇰🇿',
    mask: ['699-999-99-99', '799-999-99-99'],
  },
  {
    name: 'Kenya',
    code: '+254',
    iso: 'KE',
    flagEmoji: '🇰🇪',
    mask: '999-999999',
  },
  {
    name: 'Kiribati',
    code: '+686',
    iso: 'KI',
    flagEmoji: '🇰🇮',
    mask: '99-999',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    code: '+850',
    iso: 'KP',
    flagEmoji: '🇰🇵',
    mask: [
      '999-999',
      '9999-9999',
      '99-999-999',
      '999-9999-999',
      '191-999-9999',
      '9999-9999999999999',
    ],
  },
  {
    name: 'Korea, Republic of South Korea',
    code: '+82',
    iso: 'KR',
    flagEmoji: '🇰🇷',
    mask: '99-999-9999',
  },
  {
    name: 'Kosovo',
    code: '+383',
    iso: 'XK',
    flagEmoji: '🇽🇰',
    mask: ['99-999-999', '999-999-999'],
  },
  {
    name: 'Kuwait',
    code: '+965',
    iso: 'KW',
    flagEmoji: '🇰🇼',
    mask: '9999-9999',
  },
  {
    name: 'Kyrgyzstan',
    code: '+996',
    iso: 'KG',
    flagEmoji: '🇰🇬',
    mask: '999-999-999',
  },
  {
    name: 'Laos',
    code: '+856',
    iso: 'LA',
    flagEmoji: '🇱🇦',
    mask: ['99-999-999', '2099-999-999'],
  },
  {
    name: 'Latvia',
    code: '+371',
    iso: 'LV',
    flagEmoji: '🇱🇻',
    mask: '99-999-999',
  },
  {
    name: 'Lebanon',
    code: '+961',
    iso: 'LB',
    flagEmoji: '🇱🇧',
    mask: ['9-999-999', '99-999-999'],
  },
  {
    name: 'Lesotho',
    code: '+266',
    iso: 'LS',
    flagEmoji: '🇱🇸',
    mask: '9-999-9999',
  },
  {
    name: 'Liberia',
    code: '+231',
    iso: 'LR',
    flagEmoji: '🇱🇷',
    mask: '99-999-999',
  },
  {
    name: 'Libya',
    code: '+218',
    iso: 'LY',
    flagEmoji: '🇱🇾',
    mask: ['99-999-999', '21-999-9999'],
  },
  {
    name: 'Liechtenstein',
    code: '+423',
    iso: 'LI',
    flagEmoji: '🇱🇮',
    mask: '999-999-9999',
  },
  {
    name: 'Lithuania',
    code: '+370',
    iso: 'LT',
    flagEmoji: '🇱🇹',
    mask: '999-99-999',
  },
  {
    name: 'Luxembourg',
    code: '+352',
    iso: 'LU',
    flagEmoji: '🇱🇺',
    mask: '999-999-999',
  },
  {
    name: 'Macau',
    code: '+853',
    iso: 'MO',
    flagEmoji: '🇲🇴',
    mask: '9999-9999',
  },
  {
    name: 'Madagascar',
    code: '+261',
    iso: 'MG',
    flagEmoji: '🇲🇬',
    mask: '99-99-99999',
  },
  {
    name: 'Malawi',
    code: '+265',
    iso: 'MW',
    flagEmoji: '🇲🇼',
    mask: ['1-999-999', '9-9999-9999'],
  },
  {
    name: 'Malaysia',
    code: '+60',
    iso: 'MY',
    flagEmoji: '🇲🇾',
    mask: ['9-999-999', '99-999-999', '999-999-999', '99-999-9999'],
  },
  {
    name: 'Maldives',
    code: '+960',
    iso: 'MV',
    flagEmoji: '🇲🇻',
    mask: '999-9999',
  },
  {
    name: 'Mali',
    code: '+223',
    iso: 'ML',
    flagEmoji: '🇲🇱',
    mask: '99-99-9999',
  },
  {
    name: 'Malta',
    code: '+356',
    iso: 'MT',
    flagEmoji: '🇲🇹',
    mask: '9999-9999',
  },
  {
    name: 'Marshall Islands',
    code: '+692',
    iso: 'MH',
    flagEmoji: '🇲🇭',
    mask: '999-9999',
  },
  {
    name: 'Martinique',
    code: '+596',
    iso: 'MQ',
    flagEmoji: '🇲🇶',
    mask: '999-99-99-99',
  },
  {
    name: 'Mauritania',
    code: '+222',
    iso: 'MR',
    flagEmoji: '🇲🇷',
    mask: '99-99-9999',
  },
  {
    name: 'Mauritius',
    code: '+230',
    iso: 'MU',
    flagEmoji: '🇲🇺',
    mask: '999-9999',
  },
  {
    name: 'Mayotte',
    code: '+262',
    iso: 'YT',
    flagEmoji: '🇾🇹',
    mask: '99999-9999',
  },
  {
    name: 'Mexico',
    code: '+52',
    iso: 'MX',
    flagEmoji: '🇲🇽',
    mask: ['99-99-9999', '999-999-9999'],
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    code: '+691',
    iso: 'FM',
    flagEmoji: '🇫🇲',
    mask: '999-9999',
  },
  {
    name: 'Moldova',
    code: '+373',
    iso: 'MD',
    flagEmoji: '🇲🇩',
    mask: '9999-9999',
  },
  {
    name: 'Monaco',
    code: '+377',
    iso: 'MC',
    flagEmoji: '🇲🇨',
    mask: ['99-999-999', '999-999-999'],
  },
  {
    name: 'Mongolia',
    code: '+976',
    iso: 'MN',
    flagEmoji: '🇲🇳',
    mask: '99-99-9999',
  },
  {
    name: 'Montenegro',
    code: '+382',
    iso: 'ME',
    flagEmoji: '🇲🇪',
    mask: '99-999-999',
  },
  {
    name: 'Montserrat',
    code: '+1',
    iso: 'MS',
    flagEmoji: '🇲🇸',
    mask: '664-999-9999',
  },
  {
    name: 'Morocco',
    code: '+212',
    iso: 'MA',
    flagEmoji: '🇲🇦',
    mask: '99-9999-999',
  },
  {
    name: 'Mozambique',
    code: '+258',
    iso: 'MZ',
    flagEmoji: '🇲🇿',
    mask: '99-999-999',
  },
  {
    name: 'Myanmar',
    code: '+95',
    iso: 'MM',
    flagEmoji: '🇲🇲',
    mask: ['999-999', '9-999-999', '99-999-999'],
  },
  {
    name: 'Namibia',
    code: '+224',
    iso: 'NA',
    flagEmoji: '🇳🇦',
    mask: '99-999-9999',
  },
  {
    name: 'Nauru',
    code: '+674',
    iso: 'NR',
    flagEmoji: '🇳🇷',
    mask: '999-9999',
  },
  {
    name: 'Nepal',
    code: '+977',
    iso: 'NP',
    flagEmoji: '🇳🇵',
    mask: '99-999-999',
  },
  {
    name: 'Netherlands',
    code: '+31',
    iso: 'NL',
    flagEmoji: '🇳🇱',
    mask: '99-999-9999',
  },
  {
    name: 'Netherlands Antilles',
    code: '+599',
    iso: 'AN',
    flagEmoji: '🇧🇶',
    mask: ['999-9999', '9999-9999'],
  },
  {
    name: 'New Caledonia',
    code: '+687',
    iso: 'NC',
    flagEmoji: '🇳🇨',
    mask: '99-9999',
  },
  {
    name: 'New Zealand',
    code: '+24',
    iso: 'NZ',
    flagEmoji: '🇳🇿',
    mask: ['9-999-999', '999-999-999', '999-999-9999'],
  },
  {
    name: 'Nicaragua',
    code: '+505',
    iso: 'NI',
    flagEmoji: '🇳🇮',
    mask: '9999-9999',
  },
  {
    name: 'Niger',
    code: '+227',
    iso: 'NE',
    flagEmoji: '🇳🇪',
    mask: '99-99-9999',
  },
  {
    name: 'Nigeria',
    code: '+234',
    iso: 'NG',
    flagEmoji: '🇳🇬',
    mask: ['99-999-99', '99-999-999', '999-999-9999'],
  },
  {
    name: 'Niue',
    code: '+683',
    iso: 'NU',
    flagEmoji: '🇳🇺',
    mask: '9999',
  },
  {
    name: 'Norfolk Island',
    code: '+672',
    iso: 'NF',
    flagEmoji: '🇳🇫',
    mask: '399-999',
  },
  {
    name: 'North Macedonia',
    code: '+389',
    iso: 'MK',
    flagEmoji: '🇲🇰',
    mask: '99-999-999',
  },
  {
    name: 'Northern Mariana Islands',
    code: '+1',
    iso: 'MP',
    flagEmoji: '🇲🇵',
    mask: '670-999-9999',
  },
  {
    name: 'Norway',
    code: '+47',
    iso: 'NO',
    flagEmoji: '🇳🇴',
    mask: '999-99-999',
  },
  {
    name: 'Oman',
    code: '+968',
    iso: 'OM',
    flagEmoji: '🇴🇲',
    mask: '99-999-999',
  },
  {
    name: 'Pakistan',
    code: '+92',
    iso: 'PK',
    flagEmoji: '🇵🇰',
    mask: '999-999-9999',
  },
  {
    name: 'Palau',
    code: '+680',
    iso: 'PW',
    flagEmoji: '🇵🇼',
    mask: '999-9999',
  },
  {
    name: 'Palestine',
    code: '+970',
    iso: 'PS',
    flagEmoji: '🇵🇸',
    mask: '99-999-9999',
  },
  {
    name: 'Panama',
    code: '+507',
    iso: 'PA',
    flagEmoji: '🇵🇦',
    mask: '999-9999',
  },
  {
    name: 'Papua New Guinea',
    code: '+675',
    iso: 'PG',
    flagEmoji: '🇵🇬',
    mask: '999-99-999',
  },
  {
    name: 'Paraguay',
    code: '+595',
    iso: 'PY',
    flagEmoji: '🇵🇾',
    mask: '999-999-999',
  },
  {
    name: 'Peru',
    code: '+51',
    iso: 'PE',
    flagEmoji: '🇵🇪',
    mask: '999-999-999',
  },
  {
    name: 'Philippines',
    code: '+63',
    iso: 'PH',
    flagEmoji: '🇵🇭',
    mask: '999-999-9999',
  },
  {
    name: 'Pitcairn',
    code: '+870',
    iso: 'PN',
    flagEmoji: '🇵🇳',
    mask: '999-999-999',
  },
  {
    name: 'Poland',
    code: '+48',
    iso: 'PL',
    flagEmoji: '🇵🇱',
    mask: '999-999-999',
  },
  {
    name: 'Portugal',
    code: '+351',
    iso: 'PT',
    flagEmoji: '🇵🇹',
    mask: '99-999-9999',
  },
  {
    name: 'Puerto Rico',
    code: '+1',
    iso: 'PR',
    flagEmoji: '🇵🇷',
    mask: ['787- 999 9999', '939- 999 9999'],
  },
  {
    name: 'Qatar',
    code: '+974',
    iso: 'QA',
    flagEmoji: '🇶🇦',
    mask: '9999-9999',
  },
  {
    name: 'Reunion',
    code: '+262',
    iso: 'RE',
    flagEmoji: '🇷🇪',
    mask: '99999-9999',
  },
  {
    name: 'Romania',
    code: '+40',
    iso: 'RO',
    flagEmoji: '🇷🇴',
    mask: '99-999-9999',
  },
  {
    name: 'Russia',
    code: '+7',
    iso: 'RU',
    flagEmoji: '🇷🇺',
    mask: '999-999-99-99',
  },
  {
    name: 'Rwanda',
    code: '+250',
    iso: 'RW',
    flagEmoji: '🇷🇼',
    mask: '999-999-999',
  },
  {
    name: 'Saint Barthelemy',
    code: '+590',
    iso: 'BL',
    flagEmoji: '🇧🇱',
    mask: '999-99-99-99',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    code: '+290',
    iso: 'SH',
    flagEmoji: '🇸🇭',
    mask: '9999',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: '+1',
    iso: 'KN',
    flagEmoji: '🇰🇳',
    mask: '869-999-9999',
  },
  {
    name: 'Saint Lucia',
    code: '+1',
    iso: 'LC',
    flagEmoji: '🇱🇨',
    mask: '758-999-9999',
  },
  {
    name: 'Saint Martin',
    code: '+590',
    iso: 'MF',
    flagEmoji: '🇲🇫',
    mask: '999-999-999',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: '+508',
    iso: 'PM',
    flagEmoji: '🇵🇲',
    mask: '99-9999',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: '+1',
    iso: 'VC',
    flagEmoji: '🇻🇨',
    mask: '784-999-9999',
  },
  {
    name: 'Samoa',
    code: '+685',
    iso: 'WS',
    flagEmoji: '🇼🇸',
    mask: '99-9999',
  },
  {
    name: 'San Marino',
    code: '+378',
    iso: 'SM',
    flagEmoji: '🇸🇲',
    mask: '9999-999999',
  },
  {
    name: 'Sao Tome and Principe',
    code: '+239',
    iso: 'ST',
    flagEmoji: '🇸🇹',
    mask: '99-99999',
  },
  {
    name: 'Saudi Arabia',
    code: '+966',
    iso: 'SA',
    flagEmoji: '🇸🇦',
    mask: ['9-999-9999', '59-9999-9999'],
  },
  {
    name: 'Senegal',
    code: '+221',
    iso: 'SN',
    flagEmoji: '🇸🇳',
    mask: '99-999-9999',
  },
  {
    name: 'Serbia',
    code: '+381',
    iso: 'RS',
    flagEmoji: '🇷🇸',
    mask: '99-999-9999',
  },
  {
    name: 'Seychelles',
    code: '+248',
    iso: 'SC',
    flagEmoji: '🇸🇨',
    mask: '9-999-999',
  },
  {
    name: 'Sierra Leone',
    code: '+232',
    iso: 'SL',
    flagEmoji: '🇸🇱',
    mask: '99-999999',
  },
  {
    name: 'Singapore',
    code: '+65',
    iso: 'SG',
    flagEmoji: '🇸🇬',
    mask: '9999-9999',
  },
  {
    name: 'Sint Maarten',
    code: '+1',
    iso: 'SX',
    flagEmoji: '🇸🇽',
    mask: '721-999-9999',
  },
  {
    name: 'Slovakia',
    code: '+421',
    iso: 'SK',
    flagEmoji: '🇸🇰',
    mask: '999 999 999',
  },
  {
    name: 'Slovenia',
    code: '+386',
    iso: 'SI',
    flagEmoji: '🇸🇮',
    mask: '99-999-999',
  },
  {
    name: 'Solomon Islands',
    code: '+677',
    iso: 'SB',
    flagEmoji: '🇸🇧',
    mask: ['99999', '999-9999'],
  },
  {
    name: 'Somalia',
    code: '+252',
    iso: 'SO',
    flagEmoji: '🇸🇴',
    mask: ['9-999-999', '99-999-999'],
  },
  {
    name: 'South Africa',
    code: '+27',
    iso: 'ZA',
    flagEmoji: '🇿🇦',
    mask: '99-999-9999',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: '+500',
    iso: 'GS',
    flagEmoji: '🇬🇸',
    mask: '99999',
  },
  {
    name: 'South Sudan',
    code: '+211',
    iso: 'SS',
    flagEmoji: '🇸🇸',
    mask: '99-999-9999',
  },
  {
    name: 'Spain',
    code: '+34',
    iso: 'ES',
    flagEmoji: '🇪🇸',
    mask: '999-999-999',
  },
  {
    name: 'Sri Lanka',
    code: '+94',
    iso: 'LK',
    flagEmoji: '🇱🇰',
    mask: '99-999-9999',
  },
  {
    name: 'Sudan',
    code: '+249',
    iso: 'SD',
    flagEmoji: '🇸🇩',
    mask: '99-999-9999',
  },
  {
    name: 'Suriname',
    code: '+597',
    iso: 'SR',
    flagEmoji: '🇸🇷',
    mask: ['999-999', '999-9999'],
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: '+47',
    iso: 'SJ',
    flagEmoji: '🇸🇯',
    mask: '999-99-999',
  },
  {
    name: 'Sweden',
    code: '+46',
    iso: 'SE',
    flagEmoji: '🇸🇪',
    mask: '99-999-9999',
  },
  {
    name: 'Switzerland',
    code: '+41',
    iso: 'CH',
    flagEmoji: '🇨🇭',
    mask: '99-999-9999',
  },
  {
    name: 'Syrian Arab Republic',
    code: '+963',
    iso: 'SY',
    flagEmoji: '🇸🇾',
    mask: '99-9999-999',
  },
  {
    name: 'Taiwan',
    code: '+886',
    iso: 'TW',
    flagEmoji: '🇹🇼',
    mask: ['9999-9999', '9-9999-9999'],
  },
  {
    name: 'Tajikistan',
    code: '+992',
    iso: 'TJ',
    flagEmoji: '🇹🇯',
    mask: '99-999-9999',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    code: '+255',
    iso: 'TZ',
    flagEmoji: '🇹🇿',
    mask: '99-999-9999',
  },
  {
    name: 'Thailand',
    code: '+66',
    iso: 'TH',
    flagEmoji: '🇹🇭',
    mask: ['99-999-999', '99-999-9999'],
  },
  {
    name: 'Timor-Leste',
    code: '+670',
    iso: 'TL',
    flagEmoji: '🇹🇱',
    mask: ['999-9999', '779-99999', '789-99999'],
  },
  {
    name: 'Togo',
    code: '+228',
    iso: 'TG',
    flagEmoji: '🇹🇬',
    mask: '99-999-999',
  },
  {
    name: 'Tokelau',
    code: '+690',
    iso: 'TK',
    flagEmoji: '🇹🇰',
    mask: '9999',
  },
  {
    name: 'Tonga',
    code: '+676',
    iso: 'TO',
    flagEmoji: '🇹🇴',
    mask: '99999',
  },
  {
    name: 'Trinidad and Tobago',
    code: '+1',
    iso: 'TT',
    flagEmoji: '🇹🇹',
    mask: '868-999-9999',
  },
  {
    name: 'Tunisia',
    code: '+216',
    iso: 'TN',
    flagEmoji: '🇹🇳',
    mask: '99-999-999',
  },
  {
    name: 'Turkey',
    code: '+90',
    iso: 'TR',
    flagEmoji: '🇹🇷',
    mask: '999-999-9999',
  },
  {
    name: 'Turkmenistan',
    code: '+993',
    iso: 'TM',
    flagEmoji: '🇹🇲',
    mask: '9-999-9999',
  },
  {
    name: 'Turks and Caicos Islands',
    code: '+1',
    iso: 'TC',
    flagEmoji: '🇹🇨',
    mask: '249-999-999',
  },
  {
    name: 'Tuvalu',
    code: '+688',
    iso: 'TV',
    flagEmoji: '🇹🇻',
    mask: ['29999', '909999'],
  },
  {
    name: 'Uganda',
    code: '+256',
    iso: 'UG',
    flagEmoji: '🇺🇬',
    mask: '999-999-999',
  },
  {
    name: 'Ukraine',
    code: '+380',
    iso: 'UA',
    flagEmoji: '🇺🇦',
    mask: '99-999-99-99',
  },
  {
    name: 'United Arab Emirates',
    code: '+971',
    iso: 'AE',
    flagEmoji: '🇦🇪',
    mask: ['9-999-9999', '59-999-9999'],
  },
  {
    name: 'United Kingdom',
    code: '+44',
    iso: 'GB',
    flagEmoji: '🇬🇧',
    mask: '99-9999-9999',
  },
  {
    name: 'United States',
    code: '+1',
    iso: 'US',
    flagEmoji: '🇺🇸',
    mask: '999-999-9999',
  },
  {
    name: 'Uruguay',
    code: '+598',
    iso: 'UY',
    flagEmoji: '🇺🇾',
    mask: '9-999-99-99',
  },
  {
    name: 'Uzbekistan',
    code: '+998',
    iso: 'UZ',
    flagEmoji: '🇺🇿',
    mask: '99-999-9999',
  },
  {
    name: 'Vanuatu',
    code: '+678',
    iso: 'VU',
    flagEmoji: '🇻🇺',
    mask: ['99999', '99-99999'],
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    code: '+58',
    iso: 'VE',
    flagEmoji: '🇻🇪',
    mask: '999-999-9999',
  },
  {
    name: 'Vietnam',
    code: '+84',
    iso: 'VN',
    flagEmoji: '🇻🇳',
    mask: ['99-9999-999', '999-9999-999'],
  },
  {
    name: 'Virgin Islands, British',
    code: '+1',
    iso: 'VG',
    flagEmoji: '🇻🇬',
    mask: '284-999-9999',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: '+1',
    iso: 'VI',
    flagEmoji: '🇻🇮',
    mask: '340-999-9999',
  },
  {
    name: 'Wallis and Futuna',
    code: '+681',
    iso: 'WF',
    flagEmoji: '🇼🇫',
    mask: '99-9999',
  },
  {
    name: 'Yemen',
    code: '+967',
    iso: 'YE',
    flagEmoji: '🇾🇪',
    mask: ['9-999-999', '99-999-999', '999-999-999'],
  },
  {
    name: 'Zambia',
    code: '+260',
    iso: 'ZM',
    flagEmoji: '🇿🇲',
    mask: '99-999-9999',
  },
  {
    name: 'Zimbabwe',
    code: '+263',
    iso: 'ZW',
    flagEmoji: '🇿🇼',
    mask: '9-999999',
  },
]

export const countriesListWithEmoji = unsortedCountriesListWithEmoji.sort(
  (a, b) => {
    const indexA = (
      orderMapCountries.has(a.iso) ? orderMapCountries.get(a.iso) : Infinity
    ) as number

    const indexB = (
      orderMapCountries.has(b.iso) ? orderMapCountries.get(b.iso) : Infinity
    ) as number

    return indexA - indexB
  }
)
