import { apiErrorHandler } from '@libs/utils'
import { arbipayApi } from '@shared/http-instance'

export interface IClientRequestBody {
  name: string
  phone: string
  email: string
  date: string
  title: string
  descriptions: string
}

interface IClientResponseBody {
  return: boolean
}

export async function clientRequest(body: IClientRequestBody) {
  try {
    const url = '/client/request'
    const response = await arbipayApi.post<IClientResponseBody>(url, body, {})

    return response.data.return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    apiErrorHandler(error)
  }
}
