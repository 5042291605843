import { Box, styled } from '@mui/material'

export const StyledForMerchantsCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  background:
    'linear-gradient(101.74deg, rgba(222, 221, 200, 0.56) 50.22%, rgba(222, 221, 200, 0.2) 100%)',
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: 16,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 20,
  paddingBottom: 20,
  minWidth: 284,
  width: 284,
  transition: '0.3s',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    minWidth: 255,
    width: 255,
  },
  '&:hover': {
    boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.16)',
    '&::before': {
      opacity: 1,
    },
  },
  '&::before': {
    content: "' '",
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    transition: '0.3s',
    background: '#DEDDC8',
    borderRadius: 16,
  },
}))
