import { SxProps, Box } from '@mui/material'
import { ChangeEvent, KeyboardEvent, FocusEvent, forwardRef } from 'react'
import ReactInputMask from 'react-input-mask'

import { StyledTextField, StyledLabel } from './styled'

interface IProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onEnter?: () => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  value?: string
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  sx?: SxProps
}

export const SecretCodeField = forwardRef<HTMLInputElement, IProps>(
  function SecretCodeField(props, ref) {
    const {
      error,
      fullWidth,
      helperText,
      placeholder,
      sx,
      value,
      label,
      onChange,
      onEnter,
      onBlur,
      onFocus,
    } = props

    function keydownHandler(event: KeyboardEvent<HTMLInputElement>) {
      if (!onEnter) {
        return
      }

      const { key } = event

      if (key === 'Enter') {
        onEnter()
      }
    }

    return (
      <Box sx={sx}>
        <StyledLabel>{label}</StyledLabel>

        <ReactInputMask
          onChange={onChange}
          onKeyDown={keydownHandler}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          mask="999999"
          maskChar={null}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          children={(props: any) => (
            <StyledTextField
              {...props}
              error={error}
              helperText={helperText}
              placeholder={placeholder}
              fullWidth={fullWidth}
              autoComplete="off"
              inputRef={ref}
            />
          )}
        ></ReactInputMask>
      </Box>
    )
  }
)
