import { Container, styled } from '@mui/material'
import { appBarHeight } from '@shared/constants'

export const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: `calc(100vh - ${appBarHeight.xs})`,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    minHeight: `calc(100vh - ${appBarHeight.lg})`,
    alignItems: 'center',
    flexDirection: 'initial',
  },
}))
