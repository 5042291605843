import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Menu, MenuItem, SxProps, styled, useTheme, Box } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  languageLabels,
  languages,
  i18n,
  fetchElseTranslations,
} from '@shared/i18n'
import { LinkButton } from '@shared/ui'

interface PropsInterface {
  sx?: SxProps
  buttonSize?: 'small' | 'medium' | 'large'
  registration?: boolean
}

interface IStyledMenuProps {
  registration?: boolean
}

const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => prop !== 'registration',
})<IStyledMenuProps>(({ theme, registration }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0 0 8px 8px !important',
    borderTop: '2px solid',
    borderColor: registration ? '#032530' : theme.palette.custom.blue.light,
    [theme.breakpoints.up('lg')]: {
      marginTop: '.5rem',
    },
  },
}))

export function LanguageSwitcher({
  sx,
  buttonSize,
  registration,
}: PropsInterface) {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()

  // Show the menu when the profile button is clicked.
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // Hide the menu when the profile button is clicked.
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Select the language when the menu item is clicked.
  const handleMenuItemClick = (lang: string) => {
    localStorage.setItem('lang', lang)

    handleClose()

    if (i18n.store.data[lang]) {
      return changeLanguage(lang)
    }

    fetchElseTranslations(lang)
  }

  return (
    <Box sx={sx}>
      <LinkButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disableRipple
        size={buttonSize ?? 'medium'}
        sx={{
          fontSize: 16,
          color: '#22262B',
          fontWeight: 500,
          borderRadius: 3,
          '&:hover': {
            color: 'initial',
            backgroundColor: '#F2F2F2',
          },
        }}
      >
        {language.toUpperCase()}
      </LinkButton>

      <StyledMenu
        id="demo-simple-select"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        registration={registration}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languages.map((lang: string, index: number) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(lang)}
            sx={{
              ...(!registration && {
                color:
                  languageLabels[language] === languageLabels[lang]
                    ? theme.palette.primary.main
                    : theme.palette.custom.grey.dark,
              }),
              ...(languageLabels[language] === languageLabels[lang] &&
                registration && {
                  transform: 'scale(1.3)',
                }),
              fontWeight: 600,
            }}
          >
            {languageLabels[lang]}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  )
}
