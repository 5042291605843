import { Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { RedirectToAuthPage } from '@libs/auth'
import { AuthenticationCallback, LogoutCallback } from '@libs/auth'

import { BusinessAccountLandingPageRoute } from '@pages/business-account-landing'
import { ContactsPageRoute } from '@pages/contacts'
import { DocumentsInfoPageRoutes } from '@pages/documents-info'
import { FeesPageRoute } from '@pages/fees'
import { MainPageRoute } from '@pages/main'
import { OpenNewArbipayAccountPageRoute } from '@pages/open-new-arbipay-account'
import { PdfViewerRoutes } from '@pages/pdf-viewer'
import { PersonalAccountLandingPageRoute } from '@pages/personal-account-landing'
import { ProhibitedCountriesPageRoute } from '@pages/prohibites-countries-and-activities'
import {
  EmailVerificationRoute,
  PhoneNumberVerificationRoute,
  RegistrationFinishRoute,
} from '@pages/registration'

import { ErrorLayout, MainLayout, RegistrationLayout } from './layouts'

function ComponentForTest() {
  const form = useForm({})

  return (
    <FormProvider {...form}>
      <Container maxWidth="sm">{/*<StartRecognizeProcess />*/}</Container>
    </FormProvider>
  )
}

const browserRouter = createBrowserRouter([
  // ! Тут добавляйте компоненты которые нужно посмотреть но к ним нет доступа у изза бэка и тд
  {
    path: 'test',
    element: <ComponentForTest />,
  },
  {
    element: <RegistrationLayout />,
    children: [
      PhoneNumberVerificationRoute,
      EmailVerificationRoute,
      RegistrationFinishRoute,
    ],
  },
  {
    path: '/login',
    element: <RedirectToAuthPage />,
  },
  {
    path: '/callback',
    element: <AuthenticationCallback />,
  },
  {
    path: '/logout-callback',
    element: <LogoutCallback />,
  },
  {
    element: <MainLayout />,
    errorElement: <ErrorLayout />,
    children: [
      MainPageRoute,
      ContactsPageRoute,
      PersonalAccountLandingPageRoute,
      BusinessAccountLandingPageRoute,
      ProhibitedCountriesPageRoute,
      FeesPageRoute,
      ...DocumentsInfoPageRoutes,
      OpenNewArbipayAccountPageRoute,
      ...PdfViewerRoutes,
    ],
  },
  {
    path: '*',
    element: <ErrorLayout />,
  },
])

/**
 * Router viewer. This component is responsible for rendering the correct page
 * @returns React.ReactElement
 */
export default function RouterViewer() {
  return <RouterProvider router={browserRouter} />
}
