import { styled, TextField, TextFieldProps } from '@mui/material'

export const StyledTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    '& > div': {
      borderRadius: 8,
      padding: '12px 20px',
    },
    '& fieldset': {
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '& input': {
      padding: 0,
      fontWeight: 400,
      fontSize: 16,
      height: '32px',
      fontFeatureSettings: "'zero' on",
      color: '#032530',
      letterSpacing: '6px',
      '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus':
        {
          WebkitBoxShadow: '0 0 0px 1000px transparent inset',
          transition: 'background-color 5000s ease-in-out 0s',
        },
      '&::placeholder': {
        fontSize: 16,
        lineHeight: '24px',
        color: '#22262B',
        opacity: 0.6,
        letterSpacing: 'initial',
        [theme.breakpoints.up('lg')]: {
          fontSize: 18,
          lineHeight: '22px',
        },
      },
    },
    '& .Mui-focused': {
      borderWidth: '1px !important',
      backgroundColor: '#fff',
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '& .Mui-focused fieldset': {
      borderWidth: '1px !important',
      borderColor: '#032530' + '!important',
    },
  })
)
