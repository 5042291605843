import { styled, Typography } from '@mui/material'

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 500,
  lineHeight: '48px',
  [theme.breakpoints.up('md')]: {
    fontSize: 80,
    lineHeight: '88px',
  },
  '&:after': {
    content: '" "',
    display: 'block',
    marginTop: 32,
    width: 164,
    height: 1,
    backgroundColor: theme.palette.tailwindColors.sky[500],
    [theme.breakpoints.up('lg')]: {
      width: 200,
      height: 2,
    },
  },
}))
