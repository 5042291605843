import {
  Box,
  SxProps,
  TextFieldProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { ElementType, forwardRef } from 'react'

import { TextFieldStyledLabel } from '@shared/ui'
import CalendarIcon from './assets/calendar'
import { StyledOutlinedInput } from './styled'

interface IProps {
  value?: Date | null
  onChange: (...event: unknown[]) => void
  onBlur?: (...event: unknown[]) => void
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string | boolean
  fullWidth?: boolean
  sx?: SxProps
  disabled?: boolean
  format?: string
  disableFuture?: boolean
  disablePast?: boolean
}

export const DateField = forwardRef<HTMLInputElement, IProps>(
  function Named(props, ref) {
    const {
      value,
      onChange,
      onBlur,
      label,
      disabled,
      error,
      fullWidth,
      helperText,
      placeholder = 'DD/MM/YYYY',
      format = 'dd/MM/yyyy',
      sx,
      disableFuture,
      disablePast,
    } = props

    const theme = useTheme()
    const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

    return (
      <Box sx={{ ...sx, width: 'initial' }}>
        {lgAndUp ? (
          <>
            <TextFieldStyledLabel>{label}</TextFieldStyledLabel>

            <DesktopDatePicker
              value={value}
              onChange={onChange}
              disabled={disabled}
              format={format}
              inputRef={ref}
              disableOpenPicker={disabled}
              slots={{
                textField: StyledOutlinedInput as ElementType<TextFieldProps>,
              }}
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  placeholder: placeholder,
                  error: error,
                  helperText: error && helperText,
                  sx: {
                    mt: '0 !important',
                    '& .Mui-disabled': {
                      backgroundColor: '#FAFAFA',
                    },
                  },
                  onBlur: onBlur,
                },
                openPickerIcon: CalendarIcon,
              }}
              minDate={new Date(1900, 0, 1)}
              disableFuture={disableFuture}
              disablePast={disablePast}
            />
          </>
        ) : (
          <>
            <TextFieldStyledLabel>{label}</TextFieldStyledLabel>

            <MobileDatePicker
              value={value}
              onChange={onChange}
              format={format}
              disabled={disabled}
              inputRef={ref}
              slots={{
                textField: StyledOutlinedInput as ElementType<TextFieldProps>,
              }}
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  placeholder: placeholder,
                  error: error,
                  helperText: error && helperText,
                  sx: {
                    mt: '0 !important',
                  },
                  onBlur: onBlur,
                },
              }}
              minDate={new Date(1900, 0, 1)}
              disableFuture={disableFuture}
              disablePast={disablePast}
            />
          </>
        )}
      </Box>
    )
  }
)
