import { Box, styled } from '@mui/material'

export const ForMerchantsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.up('xs')]: {
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    justifyContent: 'flex-start',
    gap: 16,
  },
  [theme.breakpoints.up('lg')]: {
    flexWrap: 'wrap',
    overflowX: 'hidden',
    padding: 0,
    justifyContent: 'center',
    gap: 52,
  },
  '&::-webkit-scrollbar': {
    padding: '10px',
    [theme.breakpoints.up('xs')]: {
      width: 2,
      height: 2,
    },
    [theme.breakpoints.up('sm')]: {
      width: 6,
      height: 6,
    },
    background: 'transparent',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '100px',
  },
  '&::-webkit-scrollbar-thumb': {
    [theme.breakpoints.up('xs')]: {
      width: 2,
      height: 2,
    },
    [theme.breakpoints.up('sm')]: {
      width: 6,
      height: 6,
    },
    background: '#8F8F83',
    borderRadius: '100px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-track-piece:end': {
    background: '#dfded8',
    marginLeft: '30px',
    marginRight: '30px',
  },
  '&::-webkit-scrollbar-track-piece:start': {
    background: '#dfded8',
    marginLeft: '30px',
    marginRight: '30px',
  },
}))
