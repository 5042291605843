import { Box, Grid } from '@mui/material'

import { useMobileMenu } from '@widgets/app-bar'

import { MainLogoIcon } from '@shared/assets/main-logo-icon'
import { routePaths } from '@shared/constants'
import { AppLink } from '@shared/ui'

export function MainLogoBox() {
  const setMobileMenuShow = useMobileMenu((state) => state.setShow)

  return (
    <Grid item display={'flex'} alignItems={'center'}>
      <AppLink
        href={routePaths.mainPage}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          pt={0.5}
          onClick={() => setMobileMenuShow(false)}
        >
          <MainLogoIcon
            sx={{
              width: 110,
              cursor: 'pointer',
            }}
          />
        </Box>
      </AppLink>
    </Grid>
  )
}
