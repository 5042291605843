import { Buffer } from 'buffer'
import crypto from 'crypto'

// based on https://www.rfc-editor.org/rfc/rfc7636

// Explanation of the Authorization Flow (PKCE):
// In the Authorization Flow (PKCE), when the user api a login, we redirect directly to keycloak.
//                          FRONTEND ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ KEYCLOAK
//                             |                                                  |
//                             |              1. Redirect to keycloak             |
//                             |             (challenge code specified)           |
//                             | -----------------------------------------------> | ---|
//                             |                                                  |    | 2. User enters credentials
//                             |                                                  | <--|
//                             |  3. Keycloak redirects the user to our callback  |
//                        |--- | <------------------------------------------------|
// 4. Frontend parses the |    |                                                  |
//  authorization code    |    |                                                  |
// from the redirect URI  |--->|      5. Frontend api tokens with the        |
//                             |     authorization code and challenge verifier    |
//                             | -----------------------------------------------> | ---|
//                             |                                                  |    | 6. Keycloak verifies if the verifier and authorization code are correct
//                             |    7. Keycloak sends refresh and access tokens   | <--|
//                             | <------------------------------------------------|
//                             |                                                  |
//                             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// In the query parameters of the first redirect, (step 1) we specify some parameters:
// client_id -> Which client are we authentication to?
// redirect_uri -> Where should keycloak redirect the user after they enter their username and password? (with the authorization code as a query parameter)
// code_challenge_method -> always 'S256' (explained below)
// code_challenge -> calculated (explained below)
//
// What is a code challenge and a code verifier?
// these two values are a part of the PKCE extension and they are used by keycloak
// to make sure only the person that has the code VERIFIER can get the tokens of the user.
//
// - The code verifier is a random string (43 to 128 characters long) that is generated before making the first redirect.
// - The code challenge is another string that is calculated from the code verifier (sha256 hash + base64 url-safe encoding).
//
// To better understand how this works (and why everything works like this), you can imagine how the authentication would work on the keycloak side:
// 1. Keycloak saves the code challenge it gets with the first request (before the user enters their credentials)
//
// 2. After the frontend api refresh and access tokens with the authorization code, keycloak
//    calculates the challenge code again from the challenge verifier you send, and compares it to
//    the saved challenge code.
//    Or in code:
//         saved_code_challenge == base64(sha256(code_verifier))
//
// What is code_challenge_method?
// The code_challenge_method parameter can have 2 values
//  1. plain (should only used if S256 cannot be supported)
//     - code_challenge = code_verifier

//  2. S256 (required, more secure, should always be used!!!)
//     - code_challenge = base64(sha256(code_verifier))

export function generateVerifier() {
  const generateEntropy = (length: number) =>
    crypto.randomBytes(length).toString('hex')

  const codeVerifier = Array.from({ length: 7 })
    .map(() => generateEntropy(7))
    .join('-')

  // kinda like uuid but longer
  let codeChallenge = crypto
    .createHash('sha256')
    .update(Buffer.from(codeVerifier, 'ascii'))
    .digest()
    .toString('base64')

  // needed because: we use BASE64URL-ENCODE, not conventional base64
  // https://www.rfc-editor.org/rfc/rfc7636#section-4.1
  const urlSafeReplacements = [
    [/\+/g, '-'],
    [/\//g, '_'],
    [/=/g, ''],
  ]

  urlSafeReplacements.forEach(([reg, replacement]) => {
    codeChallenge = codeChallenge?.replaceAll(reg, replacement as string)
  })

  return { codeVerifier, codeChallenge }
}
