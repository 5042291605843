import { Components, Theme } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import { defaultTheme } from './default-theme'

interface ExtendedTypographyOptions extends TypographyOptions {
  text14: React.CSSProperties
  text15: React.CSSProperties
  text16: React.CSSProperties
  text16Medium: React.CSSProperties
  text18: React.CSSProperties
  text20: React.CSSProperties
  text28: React.CSSProperties
  numbers40: React.CSSProperties
  textIbmNormal: React.CSSProperties

  sectionTitle: React.CSSProperties
  subtitle3: React.CSSProperties
  subtitle5: React.CSSProperties
}

export const typographyOptions: ExtendedTypographyOptions = {
  fontFamily: [
    'Simplon Norm Trial',
    'sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
  h4: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.2,
    [defaultTheme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  },

  sectionTitle: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '120%',
    margin: 0,
    [defaultTheme.breakpoints.up('lg')]: {
      fontSize: 40,
    },
  },
  subtitle1: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '120%',
  },
  subtitle3: {
    fontSize: 25,
    fontWeight: 700,
    lineHeight: '120%',
    letterSpacing: '1px',
  },
  subtitle5: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.4px',
  },
  text14: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  text15: {
    fontFamily: 'IBM Plex Sans',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '24px',
  },
  text16: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  text16Medium: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  text18: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text20: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text28: {
    fontSize: 28,
    fontWeight: 300,
    textTransform: 'uppercase',
    lineHeight: 'normal',
  },
  numbers40: {
    fontSize: 40,
    lineHeight: '120%',
  },
  textIbmNormal: {
    fontFamily: 'IBM Plex Sans',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}

export const componentsTypographyOptions: Components<
  Omit<Theme, 'components'>
> = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        sectionTitle: 'h2',
        text14: 'p',
        text15: 'p',
        text16: 'p',
        text18: 'p',
        text20: 'p',
        numbers40: 'p',
        textIbmNormal: 'p',
        subtitle3: 'p',
      },
    },
    styleOverrides: {
      root: {
        '.colored': {
          color: defaultTheme.palette.primary.main,
        },
      },
    },
  },
}
