import { Box, Container, Typography, useTheme, Stack } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { MobileAppButtons } from '@entities/mobile-app-buttons'

import { AppLink } from '@shared/ui'

import { FooterLogo } from './assets/footer-logo'
import { ReactComponent as MastercardLogo } from './assets/mastercard-logo.svg'

import { FooterMenu, PoweredByBlock, SocialLinks } from './components'
import { StyledTypography } from './styled'

export function Footer() {
  const theme = useTheme()
  const { t } = useTranslation('components')

  return (
    <Box component="footer">
      <Box
        sx={{
          background: theme.palette.tailwindColors.sky[500],
          pt: 56 / 8,
          pb: 5,
        }}
      >
        <Container maxWidth="lg">
          <Stack
            spacing={{ xs: 5, md: null }}
            direction={{ xs: 'column', md: 'row' }}
          >
            <Box>
              <AppLink href="/">
                <FooterLogo />
              </AppLink>

              <SocialLinks />
            </Box>

            <Box
              minWidth={{ lg: 884 }}
              width={{ sm: '100%', lg: 'auto' }}
              ml={{ lg: 'auto !important' }}
            >
              <FooterMenu sx={{ color: 'white' }} />
            </Box>
          </Stack>

          <Box mt={5} display={{ xs: 'none', lg: 'block' }}>
            <MobileAppButtons />
          </Box>
        </Container>
      </Box>

      {/*List Menu*/}
      <Box
        sx={{
          backgroundColor: theme.palette.tailwindColors.gray[800],
          pt: { xs: 4.5, lg: 2.5 },
          pb: 5,
        }}
      >
        <Container maxWidth="lg">
          <Box display={{ xs: 'block', lg: 'none' }}>
            <MobileAppButtons />
          </Box>

          <Box mt={{ xs: 5, lg: 4 }}>
            <PoweredByBlock />
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.tailwindColors.white,
              }}
            >
              Ⓒ 2023 ARBITAS sro
            </Typography>

            <Typography color="white" mt={1} fontSize={12} lineHeight="18px">
              {t('footer.text')}
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Stack
          direction="row"
          spacing={2}
          alignItems={{ md: 'center' }}
          py={1.5}
        >
          <Box>
            <MastercardLogo />
          </Box>

          <StyledTypography>
            <Trans
              ns="components"
              i18nKey="footer.mastercard"
              components={{ a: <a />, span: <span /> }}
            />
          </StyledTypography>
        </Stack>
      </Container>
    </Box>
  )
}
