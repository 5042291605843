import { CircularProgress, Stack } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { configs } from '../../app-settings'
import { formEncode, getCookie, secondsToDays, setCookie } from '../../utils'
import {
  __TOKEN,
  __REFRESH_TOKEN,
  __ID_TOKEN,
  __CHALLENGE,
  useAuth,
} from '../model'

export function AuthenticationCallback() {
  const navigate = useNavigate()

  const {
    keycloakInfo: { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL },
  } = useAuth()

  const { pathToLanding, currentPath } = configs()

  const params = window.location.href
    .split('#')[1]
    .split('&')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce((acc: any, x) => {
      const [k, v] = x.split('=')

      acc[k] = v

      return acc
    }, {})

  const code = params['code']
  const cookie = getCookie(__CHALLENGE)

  useEffect(() => {
    if (!code) {
      return
    }

    const fetchTokens = async () => {
      const challenge: {
        codeChallenge: string
        codeVerifier: string
      } = JSON.parse(cookie ? (cookie as string) : 'null')

      if (!challenge) {
        return (window.location.href = pathToLanding)
      }

      const url = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`

      const payload = formEncode({
        grant_type: 'authorization_code',
        client_id: KEYCLOAK_CLIENT_ID,
        redirect_uri: `${currentPath}/callback`,
        scope: 'openid',

        // notice: we send the code verifier, not the code challenge!
        code_verifier: challenge.codeVerifier,
        code,
      })

      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      setCookie(
        __TOKEN,
        `Bearer ${response.data.access_token}`,
        secondsToDays(response.data.expires_in)
      )

      setCookie(
        __REFRESH_TOKEN,
        `Bearer ${response.data.refresh_token}`,
        secondsToDays(response.data.refresh_expires_in)
      )

      setCookie(
        __ID_TOKEN,
        `${response.data.id_token}`,
        secondsToDays(response.data.refresh_expires_in)
      )

      navigate('/')
    }

    fetchTokens()
  }, [code])

  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Stack>
  )
}
