import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const ContactsPageRoute: RouteObject = {
  path: routePaths.contactsPage,
  lazy: async () => {
    const { Contacts } = await import('./ui')

    return { Component: Contacts }
  },
}
