import { styled } from '@mui/material'
import { StyledImage } from './'

export const IPhoneImage = styled(StyledImage)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.up(431)]: {
    width: '35%',
  },
  [theme.breakpoints.up(490)]: {
    width: '30%',
  },
  [theme.breakpoints.up(520)]: {
    width: '30%',
  },
  [theme.breakpoints.up(550)]: {
    width: '28%',
  },
  [theme.breakpoints.up(600)]: {
    width: '45%',
  },
  [theme.breakpoints.up(700)]: {
    width: '42%',
  },
  [theme.breakpoints.up(800)]: {
    width: '35%',
  },
  [theme.breakpoints.up(950)]: {
    width: '32%',
  },
  [theme.breakpoints.up(1120)]: {
    width: '30%',
  },
  [theme.breakpoints.up(1199)]: {
    width: '50%',
  },
}))
