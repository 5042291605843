import { TextField, styled } from '@mui/material'

export const StyledOutlinedInput = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    minHeight: 56,
    [theme.breakpoints.up('lg')]: {
      minHeight: 60,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#009EE0',
    },
    input: {
      padding: '12.5px 14px !important',
    },
  },
  '& .MuiButtonBase-root': {
    marginRight: 0,
  },
  '& fieldset': {
    borderWidth: '1px !important',
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  '& input': {
    fontWeight: 500,
  },
}))
