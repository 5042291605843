import { Box, styled } from '@mui/material'

export const IconWrapper = styled(Box)(({ theme }) => ({
  '& svg': {
    width: 56,
    height: 56,
  },
  [theme.breakpoints.up('lg')]: {
    '& svg': {
      width: 64,
      height: 64,
    },
  },
}))
