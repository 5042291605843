import {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  areThereKeycloakCredentials,
} from '@shared/constants'

import { TAuthHostService, configs } from '../../../app-settings'
import { deleteCookie, formEncode, getCookie, setCookie } from '../../../utils'
import { __CHALLENGE } from '../constants'
import { IUser } from '../types'
import { generateVerifier } from './generate-verifier'

/**
 * This method should be used directly in cases where it is impossible to use
 * hooks or you need to redirect to the keyсloak without checking
 * authorization (then there is no need to transfer the 'user' param). In other
 * cases, you should use 'startLoginProcess' method imported from the 'useAuth'
 * hook
 */

export async function startLoginProcess(
  user?: IUser | null,
  serviceParam?: TAuthHostService
) {
  const { pathToWorkspace, pathToCrypto, pathToIdv } = configs()
  let pathToApp = pathToWorkspace

  switch (serviceParam) {
    case 'workspace':
      pathToApp = pathToWorkspace

      break
    case 'crypto':
      pathToApp = pathToCrypto

      break
    case 'idv':
      pathToApp = pathToIdv

      break
  }

  if (user) {
    window.location.href = pathToApp

    return
  }

  if (!areThereKeycloakCredentials) {
    deleteCookie(__CHALLENGE)

    throw new Error('Keycloak credentials not provided')
  }

  deleteCookie(__CHALLENGE)

  const verifier = getCookie('verifier')

  if (!verifier) {
    const challenge = generateVerifier()

    // save the challenge code and verifier,
    // they will be needed when requesting tokens from keycloak
    setCookie(__CHALLENGE, JSON.stringify(challenge), 1)

    const parameters = formEncode({
      client_id: KEYCLOAK_CLIENT_ID,
      redirect_uri: `${pathToApp}/callback`,
      response_mode: 'fragment',
      response_type: 'code',
      code_challenge: challenge.codeChallenge,
      code_challenge_method: 'S256',
      scope: 'openid',
    })

    // redirect to keycloak
    localStorage.setItem('preAuthPath', window.location.href)

    window.location.href = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/auth?${parameters}`
  }
}
