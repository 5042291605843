export type TBrowser =
  | 'Mozilla Firefox'
  | 'Samsung Internet'
  | 'Opera'
  | 'Microsoft Internet Explorer'
  | 'Microsoft Edge'
  | 'Google Chrome or Chromium'
  | 'Apple Safari'
  | 'unknown'

export function DetectionBrowser(): TBrowser {
  const sUsrAg = navigator.userAgent

  if (sUsrAg.indexOf('Firefox') > -1) {
    return 'Mozilla Firefox'
  }

  if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    return 'Samsung Internet'
  }

  if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    return 'Opera'
  }

  if (sUsrAg.indexOf('Trident') > -1) {
    return 'Microsoft Internet Explorer'
  }

  if (sUsrAg.indexOf('Edge') > -1) {
    return 'Microsoft Edge'
  }

  if (sUsrAg.indexOf('Chrome') > -1) {
    return 'Google Chrome or Chromium'
  }

  if (sUsrAg.indexOf('Safari') > -1) {
    return 'Apple Safari'
  }

  return 'unknown'
}
