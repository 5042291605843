import i18n from 'i18next'
import _ from 'lodash'
import { initReactI18next } from 'react-i18next'
import zod from 'zod'

import en from './translations/en.json'

import zodEn from './translations/zod/en.json'
import { zodI18nMap } from './zod'

export const resources = {
  en: {
    ...en,
    zod: zodEn,
  },
} as const

export const languageLabels: { [key: string]: string } = {
  en: 'EN',
  sk: 'SK',
  ru: 'RU',
}

export const languages = Object.keys(languageLabels)

const getInitialLang = (): string => {
  let systemLang: string | null = navigator.language.slice(0, 2)

  systemLang = Object.keys(languageLabels).includes(systemLang)
    ? systemLang
    : null

  let lang: string | null = localStorage.getItem('lang') || systemLang

  if (!lang) {
    lang = 'en'

    localStorage.setItem('lang', lang)
  }

  return lang
}

/**
 * Initialize i18n
 */
i18n.use(initReactI18next).init({
  returnNull: false,
  lng: getInitialLang(),
  fallbackLng: ['en'],
  ns: ['en'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: { useSuspense: false }, //this line
  nsSeparator: ':',
  resources,
  debug: false,
})

// Set zod schema validator i18n
zod.setErrorMap(zodI18nMap)

const lang = getInitialLang()

export const fetchElseTranslations = (lang: string) => {
  fetch(`/translations/landing/${lang}.json`).then(async (result) => {
    const data = await result.json()

    _.map(data, (data, ns) => {
      i18n.addResourceBundle(lang, ns, data, false, true)
    })

    i18n.changeLanguage(lang)
  })
}

if (lang !== 'en') {
  fetchElseTranslations(lang)
}

export { i18n }
