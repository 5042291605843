import { Box, styled } from '@mui/material'

export const StyledMainBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  width: '100%',
  background: '#C8DDE8',
  marginBottom: theme.spacing(4),
  height: 326,
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.spacing(2),
    width: '50% !important',
    height: 570,
    marginBottom: 0,
  },
}))
