import { Box, styled } from '@mui/material'

export const AccountCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  height: 327,
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    '& img': {
      transform: 'scale(1.1,1.1)',
    },
  },
  [theme.breakpoints.up('sm')]: {
    flex: 1,
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(7),
    height: 560,
  },
}))
