import axios from 'axios'

interface IGeolocationInfo {
  country_code: string
  country_name: string
  city: string
  postal: string
  latitude: number
  longitude: number
  IPv4: string
  state: string
}

export async function getUserIP(): Promise<IGeolocationInfo | undefined> {
  try {
    const info = await axios.get<IGeolocationInfo>(
      'https://geolocation-db.com/json/'
    )

    return info.data
  } catch {
    return
  }
}
