import { Box, Grid, Stack, useTheme } from '@mui/material'
import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AppLink } from '@shared/ui'

import { Title } from '../styled'
import { footerMenuList } from './footer-menu-list'

interface IProps {
  sx?: SxProps
}

export function FooterMenu({ sx }: IProps) {
  const { t } = useTranslation(['pages', 'components'])
  const theme = useTheme()

  return (
    <Box sx={sx}>
      <Grid spacing={{ xs: 4, md: 'none' }} container>
        {footerMenuList.map((element, index) => (
          <Grid key={index} item xs={6} md={4}>
            {!(element.title === 'components:footer.menu.Marketplace') && (
              <Title>{t(element.title as never)}</Title>
            )}

            {element.title === 'components:footer.menu.Marketplace' && (
              <AppLink
                href="/payment-services"
                sx={{ color: theme.palette.tailwindColors.white }}
              >
                <Title>{t(element.title as never)}</Title>
              </AppLink>
            )}

            <Stack spacing={{ xs: 1.5, lg: 2 }} sx={{ mt: { xs: 2, lg: 3 } }}>
              {element.list.map((link, index) => (
                <AppLink
                  sx={{
                    color: theme.palette.tailwindColors.white,
                    fontWeight: 400,
                    fontSize: { xs: 15, lg: 18 },
                    lineHeight: { xs: '18px', lg: '22px' },
                  }}
                  href={link.href}
                  key={link.title + index}
                >
                  {t(link.title as never)}
                </AppLink>
              ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
