/**
 * Method for up the first element of string
 * @param value - The string `String`
 * @returns - The string with up first letter-UP `String`
 */
export function firstElementUp(value?: string): string {
  if (!value) {
    return ''
  }

  return value[0].toUpperCase() + value.slice(1)
}
