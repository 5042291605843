import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Container,
  Drawer,
  Grid,
  IconButton,
  SxProps,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { LanguageSwitcher } from '@features/language-switcher'
import { appBarHeight } from '@shared/constants'
import { useMobileMenu } from '../model'
import { ReactComponent as ArbiIdLogo } from './assets/arbi-id-logo.svg'
import { MobileMenu, DesktopMenu, MainLogoBox } from './components'
import { StyledAppBar } from './styled'

export interface IMenuItem {
  name: string
  submenu?: IMenuItem[]
  icon?: (props: { sx?: SxProps }) => JSX.Element
  navigationLink?: string
  urlParam?: string
  key?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseLink?: any
  download?: boolean
}

interface IProps {
  registration?: boolean
}

export function AppBar({ registration }: IProps) {
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  const { show, setShow: setMobileMenuShow } = useMobileMenu()

  useEffect(() => {
    if (show) {
      document.body.setAttribute('style', 'overflow-y: hidden')
    }

    if (!show) {
      document.body.removeAttribute('style')
    }
  }, [show])

  return (
    <>
      <StyledAppBar>
        <Container>
          <Toolbar
            disableGutters={true}
            sx={{
              height: '100%',
              p: 0,
              justifyContent: 'space-between',
            }}
          >
            {registration && <ArbiIdLogo width="100px" height="initial" />}
            {!registration && <MainLogoBox />}
            {lgAndUp && !registration && <DesktopMenu />}
            {registration && <LanguageSwitcher registration />}

            {!lgAndUp && !registration && (
              <Grid
                item
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {!show && (
                  <IconButton
                    size="large"
                    edge="start"
                    aria-label="menu"
                    onClick={() => setMobileMenuShow(true)}
                    sx={{
                      padding: 0,
                    }}
                  >
                    <MenuIcon
                      sx={{
                        fontSize: 24,
                        fill: '#22262B',
                        padding: 0,
                      }}
                    />
                  </IconButton>
                )}

                {show && (
                  <IconButton
                    size="large"
                    edge="start"
                    aria-label="menu"
                    onClick={() => setMobileMenuShow(false)}
                    sx={{
                      padding: 0,
                    }}
                  >
                    <CloseIcon
                      sx={{
                        fontSize: 24,
                        fill: theme.palette.custom.grey.dark,
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            )}
          </Toolbar>
        </Container>
      </StyledAppBar>

      <Drawer
        open={show && !lgAndUp}
        anchor="top"
        variant="temporary"
        sx={{
          width: '100%',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: '100%',
            boxSizing: 'border-box',
            mt: lgAndUp ? appBarHeight.lg : appBarHeight.xs,
          },
        }}
      >
        <MobileMenu />
      </Drawer>
    </>
  )
}
