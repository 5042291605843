import { styled, Grid } from '@mui/material'

export const Wrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
  borderRadius: '12px',
  position: 'relative',
  overflow: 'hidden',

  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    borderRadius: '16px 0px 0px 16px',
  },
}))
