export const BASE_URL = import.meta.env.VITE_APP_CRYPTO_URL
export const KEYCLOAK_URL = import.meta.env.VITE_APP_KEYCLOAK_URL
export const KEYCLOAK_REALM = import.meta.env.VITE_APP_KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID
export const BANKING_URL = import.meta.env.VITE_APP_BASE_URL
export const CRYPTO_URL = import.meta.env.VITE_APP_CRYPTO_URL

export const IS_DEV_MODE = JSON.parse(
  (import.meta.env.VITE_APP_DEVELOPMENT || 'false') as string
) as boolean

export const areThereKeycloakCredentials =
  KEYCLOAK_URL && KEYCLOAK_REALM && KEYCLOAK_CLIENT_ID
