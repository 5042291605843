/* eslint-disable react/jsx-newline */
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { configs } from '../../app-settings'
import { getCookie, setCookie } from '../../utils'

const cookiesName = 'COOKIES_POLICY'

export const CookieConfirmation: FC<{ spacing?: boolean }> = ({ spacing }) => {
  const [show, setShow] = useState<boolean>(true)
  const { t } = useTranslation('components')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { pathToLanding } = configs()

  useEffect(() => {
    const cookies = getCookie(cookiesName)

    if (cookies === '1') {
      setShow(false)
    }
  }, [])

  const acceptCookiePolicy = () => {
    setCookie(cookiesName, '1', 365)

    setShow(false)
  }

  if (!show) {
    return <></>
  }

  return (
    <Box
      sx={{
        position: spacing ? 'unset' : 'fixed',
        zIndex: spacing ? 10 : 20,
        bottom: 0,
        left: 0,
        width: '100%',
        background: '#FFF',
        boxShadow: '0px -4px 7px 1px #d8d8d8;',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1.5,
          justifyContent: 'center',
          alignItems: 'center',
          mx: 'auto',
          py: '12px',
          px: '24px',
          flexWrap: 'wrap',
          position: 'relative',
        }}
      >
        {spacing && (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              background: '#FFF',
              zIndex: 12,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        )}

        <Typography
          sx={{ maxWidth: '730px' }}
          fontSize={'12px'}
          textAlign={mdAndUp ? 'start' : 'center'}
        >
          {t('cookies.confirmation')}&nbsp;
          <Box
            sx={{ color: 'GrayText' }}
            component={'a'}
            target={'_href'}
            href={`${pathToLanding}/privacy-policy#cookie-policy`}
          >
            {t('cookies.Cookie Policy')}
          </Box>
        </Typography>

        <Button onClick={acceptCookiePolicy} sx={{ minWidth: '130px' }}>
          {t('cookies.Accept & Close')}
        </Button>
      </Box>
    </Box>
  )
}
