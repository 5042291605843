import { Box, styled } from '@mui/material'

export const StyledBoxBackgroundMobile = styled(Box)(({ theme }) => ({
  background: '#ECE9DA',
  borderRadius: '12px 0px 0px 12px',
  position: 'absolute',
  top: 24,
  bottom: 0,
  right: -24,
  left: '22%',
  [theme.breakpoints.up('md')]: {
    top: 0,
  },
}))
