import { Box, styled } from '@mui/material'

import { DetectionBrowser } from '@libs/utils'

import TextBgPNG from '../../assets/oops-bg.png'
import TextBgWebP from '../../assets/oops-bg.webp'

export const StyledBox = styled(Box)(({ theme }) => ({
  width: 'max-content',
  marginTop: theme.spacing(-3),
  backgroundClip: 'text',
  backgroundImage: `url(${
    DetectionBrowser() === 'Apple Safari' ? TextBgPNG : TextBgWebP
  })`,
  backgroundSize: 'cover',
  color: 'transparent',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}))
