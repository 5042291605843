import { styled, Typography } from '@mui/material'

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.tailwindColors.white + '!important',
  fontSize: 18,
  lineHeight: '22px',
  fontWeight: 500,
  [theme.breakpoints.up('lg')]: {
    fontSize: 22,
    lineHeight: 1.2,
  },
}))
