import { Grid, styled } from '@mui/material'

export const DataItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),

  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}))
