import { styled } from '@mui/material'

export const StyledImg = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: 570,
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    maxWidth: 'initial',
    position: 'absolute',
    top: `calc(50% + ${theme.spacing(3)})`,
  },
  [theme.breakpoints.up('xl')]: {
    width: '127%',
    top: 20,
    transform: 'initial',
  },
}))
