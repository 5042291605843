import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const StandardButton = styled(LoadingButton)(() => ({
  fontFamily: 'Simplon Norm Trial',
  backgroundColor: '#009EE0',
  color: '#FFFFFF',
  textTransform: 'unset',
  fontWeight: 500,
  borderRadius: 8,
  fontSize: 20,
  lineHeight: 1.2,
  paddingTop: 16,
  paddingBottom: 16,
  minWidth: 254,
  '&:hover': {
    backgroundColor: '#0155B7',
    borderColor: '#0155B7',
    color: '#FFFFFF',
  },
  '&:active, &.Mui-focused': {
    boxShadow: 'none',
    backgroundColor: '#009EE0',
    borderColor: '#009EE0',
    color: '#FFFFFF',
  },
  '&.Mui-disabled': {
    backgroundColor: '#CCECF9',
    borderColor: '#CCECF9',
    color: '#FFFFFF',
  },
}))
