import { Typography, styled } from '@mui/material'

export const StyledTextTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isStatus404',
})<{
  isStatus404?: boolean
}>(({ theme, isStatus404 = true }) => ({
  marginTop: isStatus404 ? theme.spacing(-4.5) : theme.spacing(2.5),
  marginLeft: isStatus404 ? 0 : theme.spacing(7),
  marginBottom: theme.spacing(3),
  maxWidth: 300,
  fontSize: isStatus404 ? 24 : 32,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '140%',
  letterSpacing: '0.96px',
  [theme.breakpoints.up('md')]: {
    maxWidth: 'initial',
    marginBottom: theme.spacing(2.5),
    marginLeft: 'initial',
    marginTop: 0,
    fontSize: 32,
    fontWeight: 500,
    lineHeight: '140%',
    letterSpacing: 'initial',
  },
}))
