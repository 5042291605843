import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const EmailVerificationRoute: RouteObject = {
  path: routePaths.emailVerification,
  lazy: async () => {
    const { EmailVerification } = await import('./ui')

    return { Component: EmailVerification }
  },
}
