import { getCookie, apiErrorHandler } from '../../../utils'
import { __TOKEN } from '../constants'
import { IUserDetailInfo } from '../types'
import { instanceApi } from './api-instance'

export async function getUserAdditionalInfo() {
  try {
    const token = getCookie(__TOKEN)

    if (!token) {
      throw new Error('Unauthorized')
    }

    const url = `/user/additionalInfo/recognized`

    const response = await instanceApi.get<IUserDetailInfo>(url, {
      headers: {
        Authorization: token,
      },
    })

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    apiErrorHandler(error)
  }
}
