import { ReactNode } from 'react'
import { AppLink } from '@shared/ui'

export interface IAppBadgeProps {
  href: string
  children: ReactNode
}

export function AppBadge({ href, children }: IAppBadgeProps) {
  return (
    <AppLink href={href ? href : '#'} target={'_blank'}>
      {children}
    </AppLink>
  )
}
