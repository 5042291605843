import { Grid, styled } from '@mui/material'

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(3.5)} ${theme.spacing(2)} ${theme.spacing(3)}`,
  backgroundColor: '#F6F5EE',
  borderRadius: theme.spacing(1.5),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(6)}`,
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: 0,
    paddingBottom: theme.spacing(9),
    width: 'initial',
    borderRadius: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(10)} ${theme.spacing(7)} ${theme.spacing(10)}`,
  },
}))
