import { CircularProgress, Stack } from '@mui/material'
import { useEffect } from 'react'

import { configs } from '@libs/app-settings'
import { deleteCookie } from '../../utils'
import { useAuth, __TOKEN, __REFRESH_TOKEN } from '../model'

export function LogoutCallback() {
  const { startLoginProcess } = useAuth()

  const { getServiceFromURLSearchParams } = configs()

  const serviceFromUrlParam = getServiceFromURLSearchParams()

  useEffect(() => {
    deleteCookie(__TOKEN)

    deleteCookie(__REFRESH_TOKEN)

    deleteCookie('idToken')

    startLoginProcess(serviceFromUrlParam)
  }, [])

  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Stack>
  )
}
