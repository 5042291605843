export function FooterLogo() {
  return (
    <svg
      width="175"
      height="40"
      viewBox="0 0 175 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8121 17.0713C15.8508 17.0713 15.0714 17.8508 15.0714 18.8119C15.0714 19.7734 15.8508 20.5527 16.8121 20.5527C17.7735 20.5527 18.5527 19.7734 18.5527 18.8119C18.5527 17.8508 17.7735 17.0713 16.8121 17.0713ZM18.567 31.8757L19.2641 27.5644C19.2838 27.4984 19.2976 27.4301 19.2976 27.3578C19.2976 26.9559 18.9718 26.6303 18.5701 26.6303C18.1684 26.6303 17.8426 26.9559 17.8426 27.3578C17.8426 27.4292 17.8562 27.4971 17.8755 27.5625L18.567 31.8757ZM16.8121 23.7459C16.4103 23.7459 16.0846 24.0717 16.0846 24.4734L16.8153 39.4624L17.5394 24.4734C17.5394 24.0717 17.2138 23.7459 16.8121 23.7459ZM15.7486 27.5625C15.7678 27.4971 15.7815 27.4292 15.7815 27.3578C15.7815 26.9559 15.4556 26.6303 15.054 26.6303C14.6523 26.6303 14.3265 26.9559 14.3265 27.3578C14.3265 27.4301 14.3403 27.4984 14.36 27.5644L15.0571 31.8757L15.7486 27.5625ZM33.6241 33.3686H33.6194C30.6948 31.3209 27.1372 30.1154 23.2959 30.1154V25.3897L19.948 22.0542C20.5156 20.3798 20.8259 18.587 20.8259 16.7208C20.8259 12.5825 19.3118 8.80006 16.8121 5.88884C14.3122 8.80006 12.7981 12.5825 12.7981 16.7208C12.7981 18.587 13.1085 20.3798 13.6761 22.0542L10.3282 25.3897V30.1154C6.48687 30.1154 2.92924 31.3209 0.00461035 33.3686H0C5.48678 23.2233 9.23277 11.9988 10.8407 0.0957291H22.7833C24.3913 11.9988 28.1371 23.2233 33.6241 33.3686Z"
        fill="white"
      />

      <path
        d="M55.2106 20.0833C53.7523 17.5417 51.5856 16.0833 48.9606 15.5C54.1273 15.0833 57.7106 12.5833 57.7106 7.79167C57.7106 3.125 53.7523 0 47.8773 0H34.1689V0.166667C34.2939 0.666666 35.2939 3.75 35.2939 6.58334V23.4167C35.2939 26.25 34.2939 29.3333 34.1689 29.8333V30H42.8356V29.8333C42.7523 29.3333 41.7523 26.25 41.7523 23.4167V16.125H43.4606C45.2939 16.5417 47.5856 18.375 49.2523 22.3333C50.5023 25.25 52.1273 28.9583 53.2939 30H61.0439V29.8333C58.419 27.375 57.5439 24.3333 55.2106 20.0833ZM41.7523 1.58333H45.6689C49.0439 1.58333 50.7939 4.16667 50.7939 8.125C50.7939 12.25 48.9189 14.4167 45.5439 14.4167H41.7523V1.58333ZM42.6273 16.0417C42.5023 16.0833 42.3356 16.0833 42.2106 16.0833C42.3356 16.0417 42.4606 16.0417 42.6273 16.0417Z"
        fill="white"
      />

      <path
        d="M79.7363 14.625C82.903 13.5833 85.8197 11.5417 85.8197 7.75C85.8197 3.41667 81.903 0 76.278 0H62.6113V0.166667C62.7363 0.666666 63.7363 3.75 63.7363 6.58334V23.4167C63.7363 26.25 62.7363 29.3333 62.6113 29.8333V30H76.7363C82.3197 30 87.1947 27.4167 87.1947 22.1667C87.1947 17.6667 83.4863 15.5417 79.7363 14.625ZM74.0697 1.79167C77.4447 1.79167 79.028 4.95834 79.028 7.75C79.028 10.75 77.3197 13.9167 73.9447 13.9167H70.1947V1.79167H74.0697ZM74.528 28.2083H70.1947V15.7083H74.528C78.4863 15.7083 80.4863 18.5 80.4863 22.125C80.4863 25.9583 78.6113 28.2083 74.528 28.2083Z"
        fill="white"
      />

      <path
        d="M89.8408 30H98.4658V29.8333C98.3825 29.3333 97.3825 26.25 97.3825 23.4167V6.58334C97.3825 3.75 98.3825 0.666666 98.4658 0.166667V0H89.8408V0.166667C89.9242 0.666666 90.9242 3.75 90.9242 6.58334V23.4167C90.9242 26.25 89.9242 29.3333 89.8408 29.8333V30Z"
        fill="white"
      />

      <path
        d="M115.457 0H101.915V0.166667C101.998 0.666666 102.998 3.75 102.998 6.58334V23.4167C102.998 26.25 101.998 29.3333 101.915 29.8333V30H110.582V29.8333C110.457 29.3333 109.498 26.25 109.498 23.4167V18.25H113.998C119.665 18.25 125.332 15.2083 125.332 9.08334C125.332 4 121.332 0 115.457 0ZM113.123 16.3333H109.498V1.91667H113.248C117.248 1.91667 118.54 6.04167 118.54 9.08334C118.54 12.3333 117.123 16.3333 113.123 16.3333Z"
        fill="white"
      />

      <path
        d="M148.052 23.1667L139.344 0H131.761V0.125001C131.927 0.541667 133.011 2.91667 132.261 5.08333L126.427 21.25C125.094 25 123.594 28.3333 122.719 29.875V30H128.594V29.7917C128.386 29.125 127.052 25.3333 128.302 21.875L129.011 19.8333H139.844L141.094 23.125C142.177 26.0417 141.469 29.25 141.302 29.8333V30H151.302V29.875C150.177 28.2083 149.136 26.0833 148.052 23.1667ZM129.677 17.9583L134.302 5L139.177 17.9583H129.677Z"
        fill="white"
      />

      <path
        d="M174.292 0H168.334V0.208333C168.542 0.833333 169.334 4.29167 167.792 7.375L163.125 16.7083L158.209 6.625C156.834 3.83333 157.334 0.75 157.542 0.166667V0H147.584V0.166667C147.917 0.708334 149.834 3.41667 151.084 5.95834L157.792 18.625V23.4167C157.792 26.25 156.792 29.3333 156.709 29.8333V30H165.375V29.8333C165.292 29.3333 164.292 26.25 164.292 23.4167V18.5L170.792 6C172 3.45833 174.042 0.666666 174.292 0.166667V0Z"
        fill="white"
      />
    </svg>
  )
}
