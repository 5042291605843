import { configs } from '@libs/app-settings'

export function setCookie(name: string, value: string, days: number) {
  const { domainToCookies } = configs()
  const date = new Date()

  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

  document.cookie = `${name}=${value};path=/;domain=${domainToCookies};expires=${date.toUTCString()}`
}
