import { Container, styled } from '@mui/material'

import { appBarHeight } from '@shared/constants'

interface IProps {
  component: React.ElementType
}

export const StyledContainer = styled(Container)<IProps>(({ theme }) => ({
  minHeight: `calc(100vh - ${appBarHeight.xs})`,
  paddingTop: `calc(${appBarHeight.xs} + 20px)`,
  [theme.breakpoints.up('lg')]: {
    minHeight: `calc(100vh - ${appBarHeight.lg})`,
    paddingTop: `calc(${appBarHeight.lg} + 52px)`,
  },
}))
