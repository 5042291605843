import { Stack, styled } from '@mui/material'

import Bg from '../../assets/bg-desktop.svg'
import BgMobile from '../../assets/bg-mobile.svg'

export const StyledStack = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  minHeight: 280,
  flexGrow: 1,
  position: 'relative',
  backgroundImage: `url(${BgMobile})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  [theme.breakpoints.up('md')]: {
    maxWidth: 'initial',
    minHeight: 408,
    paddingTop: theme.spacing(6.75),
    backgroundImage: `url(${Bg})`,
  },
}))
