import { Stack, styled } from '@mui/material'

export const StyledIconContainerStack = styled(Stack)(({ theme }) => ({
  width: 24,
  height: 24,
  backgroundColor: '#C5E0FF',
  borderRadius: 1,
  [theme.breakpoints.up('sm')]: {
    width: 44,
    height: 44,
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: 2,
  },
}))
