import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: 900,
  maxHeight: '90vh',
  overflowY: 'auto',
  margin: `0 ${theme.spacing(1.5)}`,
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  '::-webkit-scrollbar': {
    width: 6,
  },
  '::-webkit-scrollbar-track': {
    [theme.breakpoints.up('lg')]: {
      borderRadius: '100px',
    },
  },
  '::-webkit-scrollbar-thumb': {
    opacity: 1,
    borderRadius: '100px',
    background: '#A5A5A5',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#A5A5A5',
  },
}))
